import { useTranslation } from 'next-i18next'
import { type FC, useRef } from 'react'

import { RADIUS_SLIDER_HALF_POINT_KMS } from '@/constants/location'
import { type UserLocation, useGetGeocodeCanadianAddressLazyQuery } from '@/generated'
import { useLocale } from '@/hooks/useLocale'
import { Checkbox } from '@/ui/atoms/checkbox'

import { RegionCheckboxWrapper } from './styled'

export type RegionCheckboxProps = {
  onLocationChange: (location: UserLocation) => void
  localLocation: UserLocation
  searchByRegionCheckboxValue: boolean
  setRegionCheckboxValue: (newValue: boolean) => void
  showMap: boolean
  setLoading: (newValue: boolean) => void
}

export const RegionCheckbox: FC<RegionCheckboxProps> = ({
  onLocationChange,
  localLocation,
  searchByRegionCheckboxValue,
  setRegionCheckboxValue,
  showMap,
  setLoading,
}) => {
  const { t } = useTranslation(['common'])
  const { apiLocale } = useLocale()
  const [fetchGeocodeAddress] = useGetGeocodeCanadianAddressLazyQuery()

  const locationName = localLocation.name[apiLocale] ?? ''
  const previousLocation = useRef<UserLocation>(localLocation)

  const toggleSetLocationToCity = async () => {
    const newCheckedValue = !searchByRegionCheckboxValue
    setRegionCheckboxValue(newCheckedValue)
    setLoading(true)

    if (!newCheckedValue) {
      // Get the street address, set necessary state and invalidate the ref value
      const streetLocation = previousLocation.current
      onLocationChange({ ...streetLocation, isRegion: false })
      return
    }

    /** The geocode is necessary on the region map to pin the user to the center of that region */
    const { data } = await fetchGeocodeAddress({
      variables: { address: locationName },
    })

    /**
     * If API couldn't find a lat/long - the feature would still work for the user
     * The map won't move and the center will remain on the lat/long from the address they had input before
     */
    const geocodeLocation = data?.geocodeCanadianAddress?.location

    /** Preserve old location values in case user un-checks the checkbox */
    previousLocation.current = localLocation

    const latitude = geocodeLocation?.latitude ?? localLocation.area?.latitude
    const longitude = geocodeLocation?.longitude ?? localLocation.area?.longitude

    onLocationChange({
      ...localLocation,
      isRegion: true,
      area:
        latitude && longitude
          ? { latitude, longitude, radius: RADIUS_SLIDER_HALF_POINT_KMS, address: '' }
          : null,
    })
  }

  return (
    <>
      {showMap && locationName ? (
        <RegionCheckboxWrapper>
          <Checkbox
            id="set-city"
            onChange={toggleSetLocationToCity}
            checked={searchByRegionCheckboxValue}
            label={t('global_header:location.search_all', {
              city: locationName,
            })}
          />
        </RegionCheckboxWrapper>
      ) : null}
    </>
  )
}
