import { useCallback } from 'react'

import { CookieRegistry } from '@/constants/cookieRegistry'
import { useGetPlaceTokenMutation } from '@/generated'
import { getCookieByKey } from '@/utils/cookies/getCookieByKey'

/**
 * Hook to provide the google place token either from the cookies if it already exists or requests it from anvil.
 *
 * @returns googlePlaceToken: google place token, googlePlaceTokenLoading: the loading state for the api call
 */
export const useGooglePlaceToken = () => {
  const [getPlaceTokenMutation] = useGetPlaceTokenMutation()

  /** TODO: Eventually move this to the GoogleMapsLoader class */
  const getGooglePlaceToken = useCallback(async () => {
    const placeTokenFromCookie = getCookieByKey(document.cookie, CookieRegistry.GOOGLE_PLACE_TOKEN)
    if (!placeTokenFromCookie) {
      await getPlaceTokenMutation()
      return getCookieByKey(document.cookie, CookieRegistry.GOOGLE_PLACE_TOKEN)
    } else {
      return placeTokenFromCookie
    }
  }, [getPlaceTokenMutation])

  return { getGooglePlaceToken }
}
