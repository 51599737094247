import ExternalLinkIcon from '@kijiji/icons/src/icons/ExternalLink'
import { FC, MouseEventHandler, ReactNode } from 'react'

import { Flex } from '@/ui/atoms/flex'
import { StyledAs } from '@/ui/typings/helpers'

import {
  ListingImageContainer,
  ListingTileBody,
  ListingTileContainer,
  ListingTileLink,
  ListingTileLinkGroup,
  Prices,
} from './styled'

export type ListingTileProps = {
  /**
   * The type of element you want text to be rendered in
   * @default 'div'
   */
  as?: StyledAs
  /**
   * Title of the shopping ad
   */
  title: string
  /**
   * External link redirecting to the product
   */
  link: string
  /**
   * Image URL of the product picture
   */
  image: ReactNode
  /**
   * Listing tile should perform on click
   */
  onClick?: MouseEventHandler
  /**
   * pricing component which holds price type specific values
   */
  pricingLabel?: ReactNode
}

// TODO: This component was originally a ShoppingAd but based on similarities with the ListingTile, the name was changed. This should be updated to abstract elements for the Shopping Card and make ListingTile more generic. - update - July 31, 2024 - shopping ads are deprecated with CAS
export const ListingTile: FC<ListingTileProps> = ({
  as = 'div',
  image,
  link,
  title,
  onClick,
  pricingLabel,
  ...rest
}) => {
  return (
    <ListingTileContainer onClick={onClick} as={as} {...rest}>
      <ListingImageContainer>{image}</ListingImageContainer>
      <ListingTileBody>
        {pricingLabel && <Prices>{pricingLabel}</Prices>}
        <ListingTileLinkGroup>
          <ListingTileLink
            href={link}
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <span>{title}</span>
          </ListingTileLink>
          <Flex alignItems="flex-end">
            <ExternalLinkIcon size="xsm" />
          </Flex>
        </ListingTileLinkGroup>
      </ListingTileBody>
    </ListingTileContainer>
  )
}
