import styled from 'styled-components'

export const StyledPasswordVisibilityButton = styled.button(
  ({ theme }) => `
  align-items: center;
  color: ${theme.colors.grey.light1};
  display: flex;

  & span {
    margin-left: ${theme.spacing.mini};
  }
`
)
