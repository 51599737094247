import { useTranslation } from 'next-i18next'
import { type FC, useState } from 'react'
import { useTheme } from 'styled-components'

import {
  MAXIMUM_RADIUS_VALUE,
  MINIMUM_RADIUS_VALUE,
  RADIUS_SLIDER_HALF_POINT_KMS,
} from '@/constants/location'
import { SLIDER_DEBOUNCE } from '@/constants/others'
import { getRecentSearchLocationFromLocation } from '@/domain/location/getRecentSearchLocationFromLocation'
import { type UserLocation } from '@/generated/graphql-types'
import { useDebounce } from '@/hooks/useDebounce'
import { useLocale } from '@/hooks/useLocale'
import { BodyText } from '@/ui/atoms/body-text'
import { Slider } from '@/ui/atoms/slider'

import {
  ConfirmButton,
  RadiusUnitsWrapper,
  SliderGroupWrapper,
  SliderWrapper,
  StyledModalFooter,
} from './styled'
import { useLocationSearchSuggestions } from './useLocationSearchSuggestions'
import { radiusSliderValueTransformer } from './utils'

export type LocationModalFooterProps = {
  onLocationSubmit: (location: UserLocation, userModifiedRadius?: boolean) => void
  onLocationChange: (location: UserLocation) => void
  localLocation: UserLocation
  searchByRegionCheckboxValue: boolean
}

export const LocationModalFooter: FC<LocationModalFooterProps> = ({
  onLocationSubmit,
  onLocationChange,
  localLocation,
  searchByRegionCheckboxValue,
}) => {
  const [userModifiedRadius, setUserModifiedRadius] = useState<boolean>(false)
  const { t } = useTranslation('common')
  const theme = useTheme()
  const { setRecentSearchLocation } = useLocationSearchSuggestions()
  const debounce = useDebounce(SLIDER_DEBOUNCE)
  const { apiLocale } = useLocale()

  const shouldHideFooter = !searchByRegionCheckboxValue && localLocation?.isRegion
  const shouldShowRadialSlider = !localLocation?.isRegion && !searchByRegionCheckboxValue
  const radius = localLocation.area?.radius || RADIUS_SLIDER_HALF_POINT_KMS

  const linearRadius = radiusSliderValueTransformer(radius, true)
  const RADIUS_SLIDER_ARGS = {
    min: MINIMUM_RADIUS_VALUE,
    max: MAXIMUM_RADIUS_VALUE,
    defaultValue: linearRadius,
    marks: { 500: RADIUS_SLIDER_HALF_POINT_KMS },
  }

  const handleSubmitLocation = (location: UserLocation, userModifiedRadius?: boolean) => {
    const recentLocation = getRecentSearchLocationFromLocation(
      localLocation,
      searchByRegionCheckboxValue,
      apiLocale
    )
    if (recentLocation) {
      setRecentSearchLocation(recentLocation)
    }
    onLocationSubmit(location, userModifiedRadius)
  }

  const handleRadiusChange = (value: number | number[]) => {
    const radiusValue = Array.isArray(value) ? value[0] : value

    if (!userModifiedRadius) {
      setUserModifiedRadius(true)
    }

    const radius = radiusSliderValueTransformer(radiusValue)
    debounce(() =>
      onLocationChange({
        ...localLocation,
        area: localLocation.area ? { ...localLocation.area, radius } : null,
      })
    )()
  }

  const handleConfirmLocationClick = () => {
    handleSubmitLocation(localLocation, userModifiedRadius)
  }

  if (shouldHideFooter) return null

  return (
    <StyledModalFooter>
      <SliderGroupWrapper>
        {shouldShowRadialSlider && (
          <>
            <RadiusUnitsWrapper
              flexDirection="row"
              alignItems="center"
              gap={theme.spacing.mini}
              alignSelf="flex-start"
              media={{
                medium: {
                  flexDirection: 'column',
                  gap: '0',
                  alignItems: 'flex-start',
                },
              }}
            >
              <BodyText weight="medium" color={theme.colors.grey.light1} size="medium">
                {t('modals.search_location.labels.radius').toUpperCase()}
              </BodyText>
              <BodyText
                data-testid="location-footer-radius-units"
                weight="medium"
                color={theme.colors.purple.primary}
                size="xLarge"
              >
                {`${radius}${t('modals.search_location.labels.radius_units')}`}
              </BodyText>
            </RadiusUnitsWrapper>

            <SliderWrapper data-testid="location-footer-radius">
              <Slider {...RADIUS_SLIDER_ARGS} value={linearRadius} onChange={handleRadiusChange} />
            </SliderWrapper>
          </>
        )}
      </SliderGroupWrapper>
      <ConfirmButton data-testid="set-location-button" onClick={handleConfirmLocationClick}>
        {t('modals.search_location.buttons.confirm')}
      </ConfirmButton>
    </StyledModalFooter>
  )
}
