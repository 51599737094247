import styled from 'styled-components'

import { Flex } from '@/ui/atoms/flex'

type SectionHeaderTextProps = {
  hasIcon: boolean
}

export const SectionHeaderText = styled(Flex)<SectionHeaderTextProps>(
  ({ theme, hasIcon }) => `

  margin-left: ${hasIcon ? theme.spacing.default : 'initial'};
`
)

export const SectionTitle = styled.span(
  ({ theme }) => `
  font-weight: ${theme.fontWeight.medium};
  line-height: ${theme.spacing.large};
  font-size: ${theme.typography.body.large.fontSize};
`
)

export const SectionSubtitle = styled.span(
  ({ theme }) => `
  color: ${theme.colors.grey.light1};
  line-height: ${theme.spacing.large};
`
)
