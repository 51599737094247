import styled from 'styled-components'

type ListTileContainerProps = {
  $layout: 'flat' | 'floating'
}

type ListTileIconProps = {
  $layout: 'flat' | 'floating'
}

export const ListTileContainer = styled.button<ListTileContainerProps>(
  ({ theme, $layout }) => {
    return `
      border: ${
        $layout === 'floating' && `0.1rem solid ${theme.colors.grey.light4}`
      };
      box-shadow: ${$layout === 'floating' && theme.boxShadow['shadow-1']};
      border-radius: ${$layout === 'floating' && theme.borderRadius.small};
      padding: ${theme.spacing.default};
      min-width: 12rem;
      display: flex;
      align-items: center;
      background-color: white;

      svg {
        width: ${theme.spacing.large};
        height: ${theme.spacing.large};
        color: ${theme.colors.grey.primary}
      }
    `
  }
)

export const ListTileText = styled.div(
  ({ theme }) => `
  margin-right: ${theme.spacing.default};
`
)

export const ListTileIcon = styled.div<ListTileIconProps>(
  ({ theme, $layout }) => {
    const size = $layout == 'flat' ? theme.spacing.large : theme.spacing.husky

    return `
      margin-right: ${theme.spacing.default};
      background-color: ${theme.colors.purple.light5};
      border-radius: 100%;
      padding: 0.5rem;
      width: ${size};
      height: ${size};
      display: flex;
      align-items: center;
      justify-content: center;
    `
  }
)
