import { HTMLAttributes } from 'react'
import styled from 'styled-components'

type DescriptionBoxBaseProps = HTMLAttributes<HTMLDivElement> & {
  maxHeight: number
  isExpanded: boolean
}

export const DescriptionBoxBase = styled.div<DescriptionBoxBaseProps>(
  ({ theme, isExpanded, maxHeight }) => `
  max-height: ${isExpanded ? 'none' : maxHeight + 'rem'}; 

  ${
    isExpanded
      ? ``
      : `
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  `
  }

  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  margin-bottom: ${theme.spacing.defaultSmall};
`
)

export const DescriptionHeadlineContainer = styled.header(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.default};
`
)
