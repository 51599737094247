import styled from 'styled-components'

type ImageUploadProps = {
  error?: string
}
export const ImageUploadWrapper = styled.div<ImageUploadProps>(
  ({ theme, error }) => `
    height: 15.2rem;
    background-color: ${theme.colors.white};
    border: .1rem solid ${
      error ? theme.colors.red.dark1 : theme.colors.grey.light3
    };
    border-radius: ${theme.borderRadius.small};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${error ? theme.colors.red.dark1 : theme.colors.grey.light1};
    `
)

export const MainWrapper = styled.div`
  max-width: 34.2rem;
`
