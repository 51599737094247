import styled from 'styled-components'

type ProgressBarContainerProps = {
  totalCells: number
}

export const ProgressBarContainer = styled.div<ProgressBarContainerProps>(
  ({ totalCells }) => `
  column-gap: .2rem;
  display: grid;
  grid-template-columns: repeat(${totalCells}, 1fr);
  width: 100%;
`
)

type ProgressBarCellProps = {
  completed: boolean
}

export const ProgressBarCell = styled.div<ProgressBarCellProps>(
  ({ theme, completed }) => `
  background-color: ${
    completed ? theme.colors.green.primary : theme.colors.grey.light2
  };
  height ${theme.spacing.mini};
`
)
