import { Section } from '@/ui/molecules/section'

import { OverviewSection as OverviewSectionType } from './Overview'
import {
  AttributeName,
  AttributeValue,
  OverviewAttributeContainer,
  OverviewSection,
  OverviewSectionAttributes,
} from './styled'

type OverviewSectionItem = {
  section: OverviewSectionType
  isLastSectionWithNoFeatures: boolean
  isSingleItem: boolean
}

const OverviewSectionItem = ({
  section,
  isLastSectionWithNoFeatures,
  isSingleItem,
}: OverviewSectionItem) => {
  return (
    <OverviewSection
      borderType="border"
      key={section.title}
      isLastSectionWithNoFeatures={isLastSectionWithNoFeatures}
    >
      <Section
        icon={section.icon}
        title={section.title}
        subtitle={section.subTitle}
      >
        {section.attributes && (
          <OverviewSectionAttributes isSingleItem={isSingleItem}>
            {section.attributes.map((sectionAttribute) => {
              return (
                <OverviewAttributeContainer key={sectionAttribute.key}>
                  <AttributeName>{`${sectionAttribute.key}: `}</AttributeName>
                  {sectionAttribute.structuredMarkupKey ? (
                    <AttributeValue
                      itemProp={sectionAttribute.structuredMarkupKey}
                    >{`${sectionAttribute.value}`}</AttributeValue>
                  ) : (
                    <AttributeValue>{sectionAttribute.value}</AttributeValue>
                  )}
                </OverviewAttributeContainer>
              )
            })}
          </OverviewSectionAttributes>
        )}
      </Section>
    </OverviewSection>
  )
}

export default OverviewSectionItem
