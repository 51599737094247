import { ButtonHTMLAttributes, forwardRef, ReactElement } from 'react'

import { StyledAs } from '@/ui/typings/helpers'

import { StyledIconButton } from './styled'

export type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  /**
   * Label that describes the button
   */
  'aria-label': string
  /**
   * Icon to be used in the button
   */
  icon: ReactElement
  /**
   * Size of the button
   * @default "medium"
   */
  size?: IconButtonSize
  /**
   * The type of element you want to be rendered in
   * @default "button"
   */
  as?: StyledAs
  /**
   * Specifies the URL of the page the link goes to
   */
  href?: string
  /**
   * Sets or retrieves the window or frame at which to target content
   */
  target?: string
  /**
   * If the button is active the icon color on idle state will be purple primary
   */
  isActive?: boolean
}

export enum IconButtonSize {
  small = 'small',
  medium = 'medium',
}

export const IconButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      'aria-label': ariaLabel,
      icon,
      size = IconButtonSize.medium,
      as,
      isActive,
      ...rest
    }: Props,
    ref
  ) => (
    <StyledIconButton
      as={as}
      aria-label={ariaLabel}
      $size={size}
      $isActive={isActive}
      ref={ref}
      {...rest}
    >
      {icon}
    </StyledIconButton>
  )
)
