import { ForwardedRef, forwardRef, HTMLAttributes } from 'react'

import { Direction } from '@/ui/typings/direction'

import { NavigationTabLink } from './styled'

export type NavigationLinkProps = HTMLAttributes<HTMLAnchorElement> & {
  isActive?: boolean
  direction?: Direction
}

/**
 * @description Renders a navigation tab link
 *
 * @param isActive - Changes the CSS of the component based on whether it is active
 * @param id - The id of the component. Can be used by parent components to determine the active state of the child
 * @param direction - Changes the CSS of the component based on whether it will be rendered in a vertical or horizontal orientation
 */
export const NavigationLink = forwardRef(
  (
    {
      isActive = false,
      id,
      children,
      direction = Direction.vertical,
      ...rest
    }: NavigationLinkProps,
    ref: ForwardedRef<HTMLAnchorElement>
  ) => (
    <NavigationTabLink
      aria-current={isActive ? 'page' : 'false'}
      direction={direction}
      id={id}
      ref={ref}
      {...rest}
    >
      {children}
    </NavigationTabLink>
  )
)

NavigationLink.displayName = 'NavigationLink'
