import UserOutlineIcon from '@kijiji/icons/src/icons/UserOutline'
import { theme } from '@kijiji/theme'
import Image from 'next/legacy/image'
import { useSession } from 'next-auth/react'

import { isUserSessionLoading } from '@/domain/user'
import { Skeleton } from '@/ui/atoms/skeleton/Skeleton'
import { isString } from '@/utils/types'
import { isProfessional } from '@/utils/user'

import { Avatar, AvatarImageContainer } from './styled'

export const DEFAULT_AVATAR = 'K'
const avatarColorsList = [
  theme.colors.blue.light4,
  theme.colors.violet.light4,
  theme.colors.yellow.light4,
  theme.colors.purple.light4,
  theme.colors.red.light4,
]

export const SessionAvatar = () => {
  const { data, status } = useSession()

  if (isUserSessionLoading(status)) {
    return (
      <Avatar>
        <Skeleton fitContainer variant="circle" data-testid="skeleton-avatar" />
      </Avatar>
    )
  }

  const userSession = data?.user
  if (!userSession?.type) {
    return null
  }

  if (isProfessional(userSession.type)) {
    return <UserOutlineIcon aria-hidden data-testid="session-pro-icon" />
  }

  const { sub, image, name } = userSession
  const isNonBlankString = isString(name) && name.length > 0

  const fallbackAvatar = isNonBlankString ? name?.[0]?.toUpperCase() : DEFAULT_AVATAR

  const colorIndex = parseInt(sub) % avatarColorsList.length
  const _color = avatarColorsList[colorIndex]

  const children = image ? (
    <AvatarImageContainer>
      <Image data-testid="session-avatar-img" src={image} alt="" layout="fill" />
    </AvatarImageContainer>
  ) : (
    <>{fallbackAvatar}</>
  )

  return (
    <Avatar data-testid="session-fsbo-avatar" color={_color}>
      {children}
    </Avatar>
  )
}
