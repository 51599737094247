import { useTranslation } from 'next-i18next'
import { useCallback, useContext, useRef, useState } from 'react'
import { ThemeContext } from 'styled-components'

import { useClickOutside } from '@/hooks/useClickOutside'
import { RotatingChevron } from '@/ui/atoms/rotating-chevron'

import { SessionAvatar } from './avatar'
import { SessionMenuContent } from './content'
import { SessionMenuButton, SessionMenuContainer } from './styled'

export const SessionMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { t } = useTranslation('global_header')
  const sessionMenuContainer = useRef<HTMLDivElement>(null)
  const theme = useContext(ThemeContext)

  const toggleMenu = useCallback(() => setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen), [])
  const dismissMenu = useCallback(() => setIsMenuOpen(false), [])
  useClickOutside(sessionMenuContainer, dismissMenu)

  return (
    <SessionMenuContainer data-testid="session-menu" ref={sessionMenuContainer}>
      <SessionMenuButton
        aria-expanded={isMenuOpen}
        aria-label={`${t('navigation_menu.aria_label')}`}
        onClick={toggleMenu}
      >
        <>
          <SessionAvatar aria-hidden />
          <RotatingChevron isOpen={isMenuOpen} left={theme.spacing.defaultSmall} />
        </>
      </SessionMenuButton>

      {isMenuOpen && <SessionMenuContent />}
    </SessionMenuContainer>
  )
}
