import { FC, HTMLAttributes, MouseEvent } from 'react'

import { PaginationEllipsis } from './PaginationEllipsis'
import { PaginationLink } from './PaginationLink'
import { PaginationNext } from './PaginationNext'
import { PaginationPrevious } from './PaginationPrevious'
import { PaginationContainer } from './styled'

export type PaginationProps = {
  /**
   * Generate the route for each link
   */
  generateUrl: (page: number) => {
    /**
     * Defines href for pagination link
     */
    href: string
    /**
     * Handles pagination link click
     */
    onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
  }
  /**
   * The aria-label for the pagination wrapper
   */
  label: string
  /**
   * The aria-label for the next button
   */
  nextLabel: string
  /**
   * The aria-label for the number page element
   */
  pageNumberLabel: string
  /**
   * The aria-label for the previous button
   */
  previousLabel: string
  /**
   * Index of the page to be selected by default
   */
  selectedIndex: number
  /**
   * The total number of pages
   */
  totalPageCount: number
} & HTMLAttributes<HTMLDivElement>

/**
 * This pagination component allows to divide amounts of content into smaller chunks across multiple pages.
 */
export const Pagination: FC<PaginationProps> = ({
  generateUrl,
  label,
  nextLabel,
  pageNumberLabel,
  previousLabel,
  selectedIndex = 1,
  totalPageCount,
  ...rest
}) => {
  const renderPaginationLinks = () => {
    /** It shouldn't show pagination if there is not at least 2 pages */
    if (totalPageCount < 2) {
      return null
    }

    /** It should show all page links if there is 5 or less pages*/
    if (totalPageCount <= 5) {
      return Array(totalPageCount)
        .fill(0)
        .map((_, index) => {
          const pageNumber = index + 1
          const isSelected = selectedIndex === pageNumber

          return (
            <PaginationLink
              generateUrl={generateUrl}
              isSelected={isSelected}
              key={`pagination-link-key-${pageNumber}`}
              pageNumber={pageNumber}
              visuallyHiddenLabel={pageNumberLabel}
            />
          )
        })
    }

    /**
     * If there are more than 5 pages AND
     * If selected page is 1-3:
     * Should show 3 first links + ellipsis + last page link
     * */
    if (selectedIndex <= 3) {
      /**
       * If 3 is selected
       * Should allow user to select next page (4)
       */
      const paginationLinks = Array(selectedIndex === 3 ? 4 : 3)
        .fill(0)
        .map((_, index) => {
          const pageNumber = index + 1
          const isSelected = selectedIndex === pageNumber

          return (
            <PaginationLink
              generateUrl={generateUrl}
              isSelected={isSelected}
              key={`pagination-link-key-${pageNumber}`}
              pageNumber={pageNumber}
              visuallyHiddenLabel={pageNumberLabel}
            />
          )
        })

      paginationLinks.push(
        <PaginationEllipsis key={`pagination-ellipsis-key-1`} />,
        <PaginationLink
          generateUrl={generateUrl}
          key={`pagination-link-key-${totalPageCount}`}
          pageNumber={totalPageCount}
          visuallyHiddenLabel={pageNumberLabel}
        />
      )

      return paginationLinks
    }

    /**
     * If there are more than 5 pages AND
     * If selected page is one of the 3 last pages:
     * Should show 1 first link + ellipsis + last 3 page links
     * */
    if (selectedIndex >= totalPageCount - 2) {
      /**
       * If 2nd to last page is selected:
       * Should allow user to select page before it
       */
      const paginationLinks = Array(
        selectedIndex === totalPageCount - 2 ? 4 : 3
      )
        .fill(0)
        .map((_, index) => {
          const pageNumber = totalPageCount - index
          const isSelected = selectedIndex === pageNumber

          return (
            <PaginationLink
              generateUrl={generateUrl}
              isSelected={isSelected}
              key={`pagination-link-key-${pageNumber}`}
              pageNumber={pageNumber}
              visuallyHiddenLabel={pageNumberLabel}
            />
          )
        })
        .reverse()

      // Push to top of array
      paginationLinks.unshift(
        <PaginationLink
          generateUrl={generateUrl}
          key={`pagination-link-key`}
          pageNumber={1}
          visuallyHiddenLabel={pageNumberLabel}
        />,
        <PaginationEllipsis key={`pagination-ellipsis-key`} />
      )

      return paginationLinks
    }

    /**
     * If there are more than 5 pages AND
     * If selected page is higher than 3 and lower than last 3:
     * Should show 1 first link + ellipsis + last 3 pages + ellipsis + last page
     * */
    return (
      <>
        <PaginationLink
          generateUrl={generateUrl}
          pageNumber={1}
          visuallyHiddenLabel={pageNumberLabel}
        />
        <PaginationEllipsis data-testid="left-ellipsis-pagination" />
        <PaginationLink
          generateUrl={generateUrl}
          pageNumber={selectedIndex - 1}
          visuallyHiddenLabel={pageNumberLabel}
        />
        <PaginationLink
          generateUrl={generateUrl}
          isSelected
          pageNumber={selectedIndex}
          visuallyHiddenLabel={pageNumberLabel}
        />
        <PaginationLink
          generateUrl={generateUrl}
          pageNumber={selectedIndex + 1}
          visuallyHiddenLabel={pageNumberLabel}
        />
        <PaginationEllipsis data-testid="right-ellipsis-pagination" />
        <PaginationLink
          generateUrl={generateUrl}
          pageNumber={totalPageCount}
          visuallyHiddenLabel={pageNumberLabel}
        />
      </>
    )
  }

  return (
    <nav aria-label={label} {...rest}>
      <PaginationContainer data-testid="pagination-list">
        <PaginationPrevious
          currentPage={selectedIndex}
          generateUrl={generateUrl}
          previousLabel={previousLabel}
        />

        {renderPaginationLinks()}

        <PaginationNext
          currentPage={selectedIndex}
          generateUrl={generateUrl}
          nextLabel={nextLabel}
          totalPageCount={totalPageCount}
        />
      </PaginationContainer>
    </nav>
  )
}
