import { FC, HTMLAttributes, ReactNode } from 'react'

import { TabList, TabListItem } from './styled'

type TabLink = {
  /**
   * Body text of the tab item
   */
  body: string | ReactNode
  /**
   * Id of the area this link controls
   */
  controls?: string
  /**
   * Href of the tab item
   */
  href: string
  /**
   * Id of the tab item
   */
  id?: string
  /**
   * Defines if the tab is selected
   */
  isSelected?: boolean
} & HTMLAttributes<HTMLAnchorElement>

export type TabsProps = {
  /**
   * Defines the direction of the tab
   */
  direction?: 'horizontal' | 'vertical'
  /**
   * Defines if tab takes full page width
   */
  isFullWidth?: boolean
  /**
   * List of tab items for the navigation
   */
  linkList: TabLink[]
}

/**
 * @description Render a navigation tab either in a horizontal or vertical direction.
 */
export const Tabs: FC<TabsProps> = ({
  direction = 'vertical',
  isFullWidth = false,
  linkList,
}) => {
  return (
    <nav>
      <TabList $direction={direction} isFullWidth={isFullWidth}>
        {linkList.map(({ body, href, isSelected, ...rest }, index) => (
          <li key={`tab-list-item-${rest.id || index}`}>
            <TabListItem
              $direction={direction}
              aria-current={isSelected ? 'page' : false}
              href={href}
              {...rest}
            >
              {body}
            </TabListItem>
          </li>
        ))}
      </TabList>
    </nav>
  )
}
