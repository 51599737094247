import KijijiDesktopLogo from '@kijiji/icons/src/icons/KijijiLogo'
import KijijiMobileLogo from '@kijiji/icons/src/icons/KijijiLogoMobile'
import { useDecision } from '@optimizely/react-sdk'
import { useTranslation } from 'next-i18next'

import { ROUTES } from '@/constants/routes'
import { FEATURE_FLAG } from '@/lib/optimizely'

import { RouteLink } from '../route-link'
import { KijijiLogoLink, LogoContainer } from './styled'

export const Logo = () => {
  const { t } = useTranslation('global_header')
  const [decision] = useDecision(FEATURE_FLAG.HEADER_SIMPLIFIED)
  const isHeaderSimplified = decision?.enabled === true

  return (
    <RouteLink route={ROUTES.HOMEPAGE}>
      <KijijiLogoLink aria-label={`${t('links.homepage.label')}`}>
        <LogoContainer isDesktop={true} isHeaderSimplified={isHeaderSimplified}>
          <KijijiDesktopLogo aria-hidden />
        </LogoContainer>

        <LogoContainer isDesktop={false} isHeaderSimplified={isHeaderSimplified}>
          <KijijiMobileLogo aria-hidden />
        </LogoContainer>
      </KijijiLogoLink>
    </RouteLink>
  )
}
