import { type ApolloClient, type NormalizedCacheObject } from '@apollo/client'

import { type SearchCategory, GetSearchCategoryDocument } from '@/generated/graphql-types'
import { initializeApollo } from '@/lib/apollo/apolloClient'

export const updateSearchCategoryCache = (
  category: SearchCategory,
  client?: ApolloClient<NormalizedCacheObject>
) => {
  if (!client) {
    client = initializeApollo()
  }

  /** Update Cache */
  const updatedCategory: SearchCategory = {
    id: category.id,
    isDomCat: category.isDomCat ?? null,
    localizedName: category.localizedName,
    name: category.name ?? null,
    parentId: category.parentId ?? null,
    path: category.path ?? [],
    seoUrl: category.seoUrl ?? null,
  }

  client.cache.writeQuery({
    query: GetSearchCategoryDocument,
    data: { srp: { searchCategory: updatedCategory } },
  })
}
