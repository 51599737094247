import { ALL_CATEGORIES_ID_NUM } from '@/constants/category'
import { ALL_CANADA_LOCATION_ID } from '@/constants/location'
import { isValidCategory } from '@/domain/category/isValidCategory'
import { isValidLocation } from '@/domain/location/isValidLocation'
import {
  type AdditionalFlags,
  type AreaLocationInput,
  type AttributeFilterInput,
  type GetSearchResultsPageByUrlQuery,
  type LocationArea,
  type PaginationInputV2,
  type SrpSortInput,
} from '@/generated/graphql-types'
import { type RefetchInput } from '@/hooks/srp/useSearchActions'
import { stripTypename } from '@/types/search'
import { mergeObjectArrays, mergePrimitiveArrays } from '@/utils/array'

import { categorizeAppliedFilters } from './categorizeAppliedFilters'

const isRefetchInputArrayReset = (newRefetchInputArray: Array<unknown> | undefined) =>
  Array.isArray(newRefetchInputArray) && newRefetchInputArray.length === 0

/**
 * Transforms Search Query data from the search fetch to a RefetchInput.
 * @param searchQuery - Search Query data from search fetch.
 * @returns The Search Query data as a RefetchInput.
 */
export const transformSearchQueryToRefetchInput = (
  searchQuery?: GetSearchResultsPageByUrlQuery['searchResultsPageByUrl']['searchQuery'],
  pagination?: PaginationInputV2,
  sorting?: SrpSortInput
): RefetchInput | undefined => {
  if (!searchQuery) return undefined

  const {
    __typename,
    area,
    location,
    category,
    searchString,
    seoUrl,
    filters,
    keywords,
    ...restSearchQuery
  } = searchQuery

  return {
    // Cat/Loc/Keywords
    ...restSearchQuery,
    keywords: keywords ?? undefined,
    categoryId: category?.id || ALL_CATEGORIES_ID_NUM,
    location: {
      id: location?.id || ALL_CANADA_LOCATION_ID,
      area: ((area && stripTypename<LocationArea>(area)) as AreaLocationInput) || undefined,
    },
    // Pagination
    offset: pagination?.offset ?? undefined,
    // Sorting
    ...(sorting || {}),
    // Filters/Flags
    ...categorizeAppliedFilters(searchQuery.filters),
  }
}

/**
 * Merges two sets of RefetchInputs for managing pending search state on the SRP.
 * @param currentRefetchInput - The current/old refetch input.
 * @param newRefetchInput - The new refetch input.
 * @returns The merged refetch input, prioritizing the newer input.
 */
export const mergeRefetchInputs = (
  currentRefetchInput: Partial<RefetchInput>,
  newRefetchInput: Partial<RefetchInput>
): Partial<RefetchInput> => {
  // Category Refinement
  const categoryId = isValidCategory(newRefetchInput.categoryId)
    ? newRefetchInput.categoryId
    : currentRefetchInput.categoryId

  // Location Refinement
  const locationId = isValidLocation(newRefetchInput.location?.id)
    ? newRefetchInput.location?.id
    : currentRefetchInput?.location?.id
  const locationArea = isValidLocation(newRefetchInput.location?.id)
    ? newRefetchInput.location?.area
    : currentRefetchInput.location?.area
  const location =
    locationId != undefined
      ? { id: locationId, ...(locationArea ? { area: locationArea } : {}) }
      : undefined

  // Filters/Flags
  const additionalFlagFilters =
    Array.isArray(newRefetchInput.additionalFlagFilters) ||
    Array.isArray(currentRefetchInput.additionalFlagFilters)
      ? // TODO: How to handle a removal of a flag in new Refetch?
        mergePrimitiveArrays<AdditionalFlags>(
          newRefetchInput.additionalFlagFilters || [],
          currentRefetchInput.additionalFlagFilters || []
        )
      : undefined

  const attributeFilters = isRefetchInputArrayReset(newRefetchInput.attributeFilters)
    ? []
    : mergeObjectArrays<AttributeFilterInput>(
        currentRefetchInput.attributeFilters || [],
        newRefetchInput.attributeFilters || [],
        'filterName'
      )

  const dateRangeFilters = isRefetchInputArrayReset(newRefetchInput.dateRangeFilters)
    ? []
    : mergeObjectArrays(
        currentRefetchInput.dateRangeFilters ?? [],
        newRefetchInput.dateRangeFilters ?? [],
        'filterName'
      )

  const dateFilters = isRefetchInputArrayReset(newRefetchInput.dateFilters)
    ? []
    : mergeObjectArrays(
        currentRefetchInput.dateFilters ?? [],
        newRefetchInput.dateFilters ?? [],
        'filterName'
      )

  const rangeFilters = isRefetchInputArrayReset(newRefetchInput.rangeFilters)
    ? []
    : mergeObjectArrays(
        currentRefetchInput.rangeFilters ?? [],
        newRefetchInput.rangeFilters ?? [],
        'filterName'
      )

  return {
    // Cat/Loc/Keywords
    categoryId: categoryId ?? undefined,
    location: location || undefined,
    keywords: newRefetchInput.keywords ?? currentRefetchInput.keywords,

    // Pagination
    offset: newRefetchInput.offset ?? currentRefetchInput.offset ?? undefined,

    // Sorting
    by: newRefetchInput.by || currentRefetchInput.by || undefined,
    direction: newRefetchInput.direction || currentRefetchInput.direction || undefined,

    // Filters/Flags
    additionalFlagFilters: additionalFlagFilters?.length ? additionalFlagFilters : undefined,
    attributeFilters,
    dateRangeFilters,
    dateFilters,
    rangeFilters,
  }
}

/**
 * Prepares a refetch input for a search url request by providing defaults for missing required properties and cleaning undefined values.
 * @param newRefetchInput - The new refetch input.
 * @param currentRefetchInput - The current/old refetch input if search is being updated.
 * @returns The prepared refetch input for a search request.
 */
export const prepareRefetchInputForRequest = (
  newRefetchInput: Partial<RefetchInput>,
  currentRefetchInput?: Partial<RefetchInput>
): RefetchInput => {
  const mergedInput = currentRefetchInput
    ? mergeRefetchInputs(currentRefetchInput, newRefetchInput)
    : newRefetchInput

  const attributeFilters = mergedInput.attributeFilters?.filter(
    ({ values }) => values && values.length > 0
  )
  const dateRangeFilters = mergedInput.dateRangeFilters?.filter(
    ({ start, end }) => !!start || !!end
  )
  const dateFilters = mergedInput.dateFilters?.filter(({ value }) => !!value)
  const rangeFilters = mergedInput.rangeFilters?.filter(
    ({ minValue, maxValue }) => minValue != undefined || maxValue != undefined
  )

  const filters = {
    ...(mergedInput.additionalFlagFilters?.length
      ? { additionalFlagFilters: mergedInput.additionalFlagFilters }
      : {}),
    ...(attributeFilters?.length ? { attributeFilters } : {}),
    ...(dateRangeFilters?.length ? { dateRangeFilters } : {}),
    ...(dateFilters?.length ? { dateFilters } : {}),
    ...(rangeFilters?.length ? { rangeFilters } : {}),
  }

  return {
    ...filters,
    ...(mergedInput.keywords ? { keywords: mergedInput.keywords } : {}),
    ...(mergedInput.topAdCount != undefined ? { topAdCount: mergedInput.topAdCount } : {}),
    categoryId: mergedInput.categoryId || ALL_CANADA_LOCATION_ID,
    location: mergedInput.location || { id: ALL_CANADA_LOCATION_ID },
    offset: mergedInput.offset || 0,
    by: mergedInput.by,
    direction: mergedInput.direction,
  }
}
