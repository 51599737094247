import { type RecentSearchLocation } from '@/components/shared/search-location-modal/useLocationSearchSuggestions'

import { isDuplicateRecentSearchLocation } from './isDuplicateRecentSearchLocation'

export const dedupeAndSliceRecentLocations = (
  recentSearchLocations: RecentSearchLocation[],
  newLocation: RecentSearchLocation
): RecentSearchLocation[] => {
  const filteredRecents = recentSearchLocations.filter(
    (recentLocation: RecentSearchLocation) =>
      !isDuplicateRecentSearchLocation(recentLocation, newLocation)
  )
  return [newLocation, ...filteredRecents].slice(0, 4)
}
