import ChevronIcon from '@kijiji/icons/src/icons/Chevron'
import CloseIcon from '@kijiji/icons/src/icons/Close'
import { HTMLAttributes, MouseEventHandler } from 'react'
import { useTheme } from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'

import { ListTileContainer, ListTileIcon, ListTileText } from './styled'

export type ListTileProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Determine which icon to show (X or >)
   * @default link
   */
  actionType: 'close' | 'link'
  /**
   * List tile description
   */
  description?: string
  /**
   * Second line of description
   */
  descriptionTwo?: string
  /**
   * Determine the card appearance
   * @default floating
   */
  layout: 'flat' | 'floating'
  /**
   * List tile should perform on click
   */
  onClick: MouseEventHandler
  /**
   * List tile title
   */
  title: string
}

/**
 * @description List tile component that requires a title and optionally an icon and description
 */

export const ListTile = ({
  actionType = 'link',
  children,
  description,
  descriptionTwo,
  layout = 'floating',
  onClick,
  title,
}: ListTileProps) => {
  const theme = useTheme()

  return (
    <ListTileContainer onClick={onClick} $layout={layout}>
      {children && <ListTileIcon $layout={layout}>{children}</ListTileIcon>}

      <ListTileText>
        <BodyText size="medium" color={theme.colors.grey.primary}>
          {title}
        </BodyText>

        {description && (
          <BodyText size="small" color={theme.colors.grey.light1}>
            {description}
          </BodyText>
        )}

        {descriptionTwo && (
          <BodyText size="small" color={theme.colors.grey.light1}>
            {descriptionTwo}
          </BodyText>
        )}
      </ListTileText>

      {actionType == 'link' ? <ChevronIcon /> : <CloseIcon />}
    </ListTileContainer>
  )
}
