import { FC, HTMLAttributes } from 'react'
import { useTheme } from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'

type PaginationEllipsisProps = HTMLAttributes<HTMLLIElement>

export const PaginationEllipsis: FC<PaginationEllipsisProps> = (props) => {
  const { colors } = useTheme()

  return (
    <li aria-hidden="true" data-testid="pagination-ellipsis" {...props}>
      <BodyText as="span" color={colors.grey.light1}>
        ...
      </BodyText>
    </li>
  )
}
