/**
 * Checks if two string arrays have the same elements.
 * @param arr1 - The first string array.
 * @param arr2 - The second string array.
 * @returns True if the arrays have the same elements, false otherwise.
 */
export const stringArraysHaveSameElements = (arr1: string[], arr2: string[]): boolean => {
  const set1 = new Set(arr1)
  const set2 = new Set(arr2)

  return set1.size === set2.size && arr1.every((value) => set2.has(value))
}

/**
 * Merges two arrays of primitives and removes duplicates.
 * @param arr1 - The first array.
 * @param arr2 - The second array.
 * @returns The merged array with duplicates removed.
 */
export const mergePrimitiveArrays = <T = string>(arr1: Array<T>, arr2: Array<T>) => {
  return [...new Set([...arr1, ...arr2])]
}

/**
 * Merges two arrays of objects based on a unique key, the second array's values overrides any duplicates from the first.
 * @param _arr1 - The first array.
 * @param arr2 - The second array.
 * @param key - The unique key to merge the arrays on.
 * @returns The merged array of objects or undefined if merged array is empty.
 */
export const mergeObjectArrays = <T = unknown>(arr1: Array<T>, arr2: Array<T>, key: keyof T) => {
  const mergedArray = Array.from(
    new Map([...arr1, ...arr2].map((item) => [item[key], item])).values()
  )

  return mergedArray.length ? mergedArray : undefined
}
