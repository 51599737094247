import { theme } from '@kijiji/theme'
import DOMPurify from 'dompurify'
import {
  FC,
  HTMLAttributes,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTheme } from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'
import { Button } from '@/ui/atoms/button'
import { Card } from '@/ui/atoms/card'
import { HeadlineText } from '@/ui/atoms/headline-text'

import { DescriptionBoxBase, DescriptionHeadlineContainer } from './styled'

type DescriptionBoxBaseProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * The text content of the title
   */
  title?: string
  /**
   * The copy for the button when the description box is in the expanded state
   */
  expandedText: string
  /**
   * The copy for the button when the description box is in the collapsed state
   */
  collapsedText: string
  /**
   * Optional line height in **REMs** for calculating the height of the collapsed view, default line height is 2.4rem.
   */
  lineHeight?: string
  /**
   * A call back that can be passed to this component to fire when the expand/collapse button is clicked
   */
  onExpandCollapseClick?: (state: DescriptionState) => void
}

type DescriptionBoxWithDescriptionProps = DescriptionBoxBaseProps & {
  /**
   * The text content of description box
   */
  description: string
  /**
   * Children that is rendered inside the expandable box
   */
  children?: never
}
type DescriptionBoxWithChildrenProps = DescriptionBoxBaseProps & {
  /**
   * The text content of description box
   */
  description?: never
  /**
   * Children that is rendered inside the expandable box
   */
  children: React.ReactNode
}

export type DescriptionBoxProps =
  | DescriptionBoxWithDescriptionProps
  | DescriptionBoxWithChildrenProps

export enum DescriptionState {
  NOT_EXPANDABLE = 'NOT_EXPANDABLE',
  COLLAPSED = 'COLLAPSED',
  EXPANDED = 'EXPANDED',
}

const LINE_HEIGHT = theme.typography.body.medium.lineHeight
const LINE_COUNT = 5

export const DescriptionBox: FC<DescriptionBoxProps> = ({
  title,
  collapsedText,
  description,
  expandedText,
  lineHeight = LINE_HEIGHT,
  onExpandCollapseClick,
  children,
  ...props
}) => {
  // The initial state should be expanded so the component can calculate the height of the expanded description.
  const [descriptionState, setDescriptionState] = useState<DescriptionState>(
    DescriptionState.EXPANDED
  )
  const descriptionRef = useRef<HTMLDivElement | null>(null)
  const theme = useTheme()

  const maxCollapsedHeight = parseFloat(lineHeight) * 10 * LINE_COUNT

  const updateDescription = useCallback(() => {
    const innerHeight = descriptionRef.current?.offsetHeight || 0

    if (innerHeight > maxCollapsedHeight) {
      if (descriptionState !== DescriptionState.NOT_EXPANDABLE) {
        setDescriptionState(DescriptionState.COLLAPSED)
      }
    } else {
      setDescriptionState(DescriptionState.NOT_EXPANDABLE)
    }
  }, [descriptionState])

  const getTextLinkDescription = () => {
    switch (descriptionState) {
      case DescriptionState.COLLAPSED:
        return collapsedText
      case DescriptionState.EXPANDED:
        return expandedText
      default:
        return ''
    }
  }

  // replaces multiple line breaks with a single br tag for the condensed view
  const condensedDescription = useMemo(() => {
    if (!description) return ''
    return description.replace(/(<br\s*\/?>\s*)+/g, '<br/>')
  }, [])

  const handleExpandCollapseClick = useCallback(() => {
    if (descriptionState === DescriptionState.COLLAPSED) {
      setDescriptionState(DescriptionState.EXPANDED)
      onExpandCollapseClick?.(DescriptionState.EXPANDED)
    } else {
      setDescriptionState(DescriptionState.COLLAPSED)
      onExpandCollapseClick?.(DescriptionState.COLLAPSED)
    }
  }, [descriptionState])

  useLayoutEffect(() => {
    updateDescription()
  }, [])

  return (
    <Card>
      {title && (
        <DescriptionHeadlineContainer>
          <HeadlineText
            size="large"
            weight="medium"
            as="h3"
            color={theme.colors.grey.primary}
          >
            {title}
          </HeadlineText>
        </DescriptionHeadlineContainer>
      )}
      <DescriptionBoxBase
        isExpanded={descriptionState === DescriptionState.EXPANDED}
        maxHeight={maxCollapsedHeight / 10}
        ref={descriptionRef}
        {...props}
      >
        {children ||
          (description && (
            <BodyText
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  descriptionState === DescriptionState.COLLAPSED
                    ? condensedDescription
                    : description
                ),
              }}
            />
          ))}
      </DescriptionBoxBase>
      {descriptionState !== DescriptionState.NOT_EXPANDABLE && (
        <Button
          onClick={handleExpandCollapseClick}
          variant="secondary"
          size="small"
        >
          {getTextLinkDescription()}
        </Button>
      )}
    </Card>
  )
}
