import styled from 'styled-components'

import {
  KEYBOARD_FOCUSED_OUTLINE,
  TRANSITION_STYLE,
} from '@/ui/constants/commonStyles'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'
import { Direction } from '@/ui/typings/direction'

import { NavigationLinkProps } from './NavigationLink'

// These styles are being extended by the horizontalTabLink atom.
export const NavigationTabLink = styled.a<NavigationLinkProps>(
  ({ theme, direction }) => {
    // conditional highlight styles for when the tab is hovered or selected
    const highlightBorderStyles = (color: string) => {
      if (direction === Direction.horizontal) {
        return `
          border-bottom: ${theme.spacing.mini} solid ${color};
          bottom: 0;
        `
      } else {
        return `
          border-left: ${theme.spacing.mini} solid ${color};
          top: 0;
        `
      }
    }

    // conditional styles applied to the selected tab
    const selectedStyles = () => {
      if (direction === Direction.horizontal) {
        return ``
      } else {
        return `
          color: ${theme.colors.grey.primary};
          font-weight: bold;
        `
      }
    }

    // conditional styles applied all tabs
    const directionalStyles = () => {
      if (direction === Direction.horizontal) {
        return ``
      } else {
        return `
          border: 0.1rem solid ${theme.colors.grey.light4};
        `
      }
    }

    return `
    ${TRANSITION_STYLE}
    ${KEYBOARD_FOCUSED_OUTLINE}
    ${styleFromTypography(theme.typography.body.medium)}
    ${directionalStyles()}
    align-items: center;
    background-color: ${theme.colors.white};
    color: ${theme.colors.grey.light1};
    cursor: pointer;
    display: flex;
    font-family: inherit;
    height: 5.6rem;
    overflow: hidden;
    padding: ${theme.spacing.default};
    text-decoration:none;
    width: 100%;
    position: relative;

    &[aria-current="page"],
    &:focus,
    &:active {
      ${selectedStyles()}
    }

    &::after {
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
    }

    &[aria-current="page"]::after {
      ${highlightBorderStyles(theme.colors.green.primary)}
    }

    &:hover:not([aria-current="page"])::after {
      ${highlightBorderStyles(theme.colors.grey.light3)}
    }
    `
  }
)
