import NewAltIcon from '@kijiji/icons/src/icons/NewAlt'
import { ChangeEventHandler, createRef, FC, RefObject } from 'react'

import { BodyText } from '@/ui/atoms/body-text'
import { TextInput } from '@/ui/atoms/text-input'
import { createSequentialId } from '@/ui/helpers/createSequentialId'
import { getAriaDescribedByValue } from '@/ui/helpers/getAriaDescribedByValue'
import { FormControl } from '@/ui/molecules/form-control'
import { CommonInputFieldProps } from '@/ui/typings/commonTextInput'

import { ImageUploadWrapper, MainWrapper } from './styled'

export type ImageUploadProps = {
  /**
   * Callback to be called when the value of the field changes
   */
  onChange: (changes: ChangeEventHandler<HTMLInputElement>) => void
} & Omit<CommonInputFieldProps, 'maxLength' | 'value' | 'bottom'>

/**
 * @description Image upload component
 */
export const ImageUpload: FC<ImageUploadProps> = ({
  error,
  id,
  label,
  helperText,
  onChange,
  ...rest
}) => {
  const inputRef: RefObject<HTMLInputElement> = createRef()

  const sequentialErrorId =
    error && createSequentialId(`${id}-image-upload-field-error-`)()

  const ariaDescribedByValue = getAriaDescribedByValue(
    sequentialErrorId,
    helperText,
    id
  )

  const focusFileInput = (): void => {
    if (inputRef.current !== null) {
      inputRef.current.click()
    }
  }

  return (
    <MainWrapper>
      <FormControl
        error={error}
        id={id}
        isFullWidth
        errorId={sequentialErrorId}
        helperText={helperText}
      >
        <ImageUploadWrapper error={error} onClick={focusFileInput}>
          <NewAltIcon data-testid="file-upload-icon" />
          <BodyText data-testid="file-upload-label">{label}</BodyText>
        </ImageUploadWrapper>
        <TextInput
          data-testid={`file-input-${id}`}
          aria-describedby={ariaDescribedByValue}
          errorId={sequentialErrorId}
          multiple
          accept="image/*"
          id={id}
          ref={inputRef}
          type="file"
          onChange={onChange}
          hidden
          {...rest}
        />
      </FormControl>
    </MainWrapper>
  )
}
