import { type ReactNode } from 'react'

import { Tooltip } from '@/ui/atoms/tool-tip/Tooltip'

import { MapControlButtonBase } from './styled'

export type MapControlButtonProps = {
  onClick: () => void
  children: ReactNode
  id: string
  tooltipText: string
  isActive?: boolean
} & React.HTMLAttributes<HTMLButtonElement>

export const MapControlButton = ({
  onClick,
  children,
  id,
  tooltipText,
  isActive,
  ...props
}: MapControlButtonProps) => {
  return (
    <Tooltip orientation="right" tooltipId={id} tooltipText={tooltipText}>
      <MapControlButtonBase disabled={isActive} onClick={onClick} isActive={isActive} {...props}>
        {children}
      </MapControlButtonBase>
    </Tooltip>
  )
}
