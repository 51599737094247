import styled from 'styled-components'

import { Panel, PanelProps } from '../panel'

export type Direction = 'bottom' | 'right' | 'top' | 'left'

export type DrawerContainerProps = PanelProps & {
  direction: Direction
  isOpen: boolean
  maxHeight: string
  maxWidth: string
}

export const DrawerContainer = styled(Panel)<DrawerContainerProps>(({
  direction,
  isOpen,
  maxHeight,
  maxWidth,
  theme,
}) => {
  // Default: slide in from bottom
  let transform = `translateY(${isOpen ? '0' : '100%'})`
  let top = 'unset'
  let bottom = '0'
  let left = '0'
  let right = '0'

  //Update transform value based on direction prop
  if (direction === 'left') {
    // Slide in from left
    transform = `translateX(${isOpen ? '0' : '-100%'})`
    top = '0'
    right = 'unset'
  } else if (direction === 'right') {
    // Slide in from right
    transform = `translateX(${isOpen ? '0' : '100%'})`
    top = '0'
    left = 'unset'
  } else if (direction === 'top') {
    // Slide in from top
    transform = `translateY(${isOpen ? '0' : '-100%'})`
    top = '0'
    bottom = 'unset'
  }

  return `
    bottom: ${bottom};
    left: ${left};
    max-height: ${maxHeight};
    max-width: ${maxWidth};
    overflow: auto auto;
    padding: ${theme.spacing.xLarge} ${theme.spacing.giant} ${theme.spacing.xLarge} ${theme.spacing.husky};
    position: fixed;
    right: ${right};
    top: ${top};
    transform: ${transform};
    transition: transform 0.3s ease-in-out;
    width: unset;
    `
})

export const CloseButton = styled.button(
  ({ theme }) => `
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    right: ${theme.spacing.default};
    top: ${theme.spacing.default};

  > svg {
      height: ${theme.spacing.large};
      width: ${theme.spacing.large};
    }
    `
)
