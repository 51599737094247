import { ThemeProps } from '@kijiji/theme'
import { BorderRadiusKeys } from '@kijiji/theme'
import { ReactElement } from 'react'

import { BadgeElement } from './styled'

export enum BadgeVariant {
  LIGHT1 = 'light1',
  LIGHT3 = 'light3',
}

export enum BadgeSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xLarge',
}

export type BadgeProps = {
  /**
   * text used inside the badge
   */
  label: string
  /**
   * variant of the badge
   */
  variant?: BadgeVariant
  /**
   * size of the badge
   */
  size?: BadgeSize
  /*
   * border radius of the badge
   */
  borderRadius?: BorderRadiusKeys
  /*
   * icon to be used on the left side of the badge
   */
  leftIcon?: ReactElement
  /*
   * Defines the color of the label text
   */
  color?: string
  /*
   * Defines the color of the background
   */
  backgroundColor?: string
  /**
   * Defines font weight of the label text
   * @default 'regular'
   */
  weight?: keyof ThemeProps['fontWeight']
}

/**
 * @description Badge to give information related to context of where ever its used.
 * It supports only text for now. In future can be adapted to hold colors, icons and backgrounds
 */

export const Badge = ({
  label,
  size = BadgeSize.MEDIUM,
  variant = BadgeVariant.LIGHT1,
  borderRadius = 'small',
  leftIcon,
  color,
  backgroundColor,
  weight = 'regular',
}: BadgeProps) => {
  return (
    <BadgeElement
      size={size}
      variant={variant}
      borderRadius={borderRadius}
      color={color}
      backgroundColor={backgroundColor}
      weight={weight}
    >
      {leftIcon}
      {label}
    </BadgeElement>
  )
}
