import { useCallback, useState } from 'react'

import { TextLink } from '@/ui/atoms/text-link'

import { BodyTextProps } from '../..'
import { ReadMoreBodyText } from './styled'
import { trimAndAddEllipsis } from './trimAndAddEllipsis'

type Props = {
  maxChars: number
  buttonCollapsedText: string
  buttonExpandedText?: string
  children: string
  isButtonHiddenOnExpand?: boolean
} & BodyTextProps

export enum State {
  COLLAPSED = 'COLLAPSED',
  EXPANDED = 'EXPANDED',
}

const readMoreTextId = 'read-more-text'
export const ReadMoreText = ({
  maxChars = 320,
  buttonCollapsedText,
  buttonExpandedText,
  children,
  isButtonHiddenOnExpand = true,
  size,
  ...rest
}: Props) => {
  const [state, setState] = useState<State>(State.COLLAPSED)

  const handleClick = useCallback(() => {
    if (state === State.COLLAPSED) {
      setState(State.EXPANDED)
    } else {
      setState(State.COLLAPSED)
    }
  }, [state])

  const expandedText = children
  const collapsedText = trimAndAddEllipsis({ text: children, maxChars })

  return (
    <>
      <ReadMoreBodyText id={readMoreTextId} size={size} {...rest}>
        {state === State.COLLAPSED ? collapsedText : expandedText}
      </ReadMoreBodyText>
      {isButtonHiddenOnExpand && state === State.EXPANDED ? null : (
        <>
          {' '}
          <TextLink
            as="button"
            hasUnderline
            variant="secondary"
            onClick={handleClick}
            size={size}
            aria-expanded={state === State.EXPANDED}
            aria-controls={readMoreTextId}
          >
            {state === State.COLLAPSED
              ? buttonCollapsedText
              : buttonExpandedText}
          </TextLink>
        </>
      )}
    </>
  )
}
