import { ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { TRANSITION_STYLE } from '@/ui/constants/commonStyles'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'
import { RequiredPickMap } from '@/ui/typings/helpers'

import { TabsProps } from './Tabs'

const horizontalTabStyle = (theme: ThemeProps) => `
  padding: 0;
  overflow-x: scroll;

  ${MEDIA_QUERIES(theme).medium}{
    padding: 0 5.8rem;
  }
`

const verticalTabStyle = (theme: ThemeProps) => `
  flex-direction: column;

  & li:not(:last-of-type) {
    border-bottom: .1rem solid ${theme.colors.grey.light4};
  } 

  ${MEDIA_QUERIES(theme).medium}{
    min-width: 33.6rem;
  }
`
type TabListProps = RequiredPickMap<TabsProps, 'direction'> &
  Required<Pick<TabsProps, 'isFullWidth'>>

export const TabList = styled.ul<TabListProps>(
  ({ theme, $direction, isFullWidth }) => `
  background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius.small};
  border: .1rem solid ${theme.colors.grey.light4};
  box-shadow: ${theme.boxShadow['shadow-1']};
  display: flex;
  list-style-type: none;
  max-width: ${isFullWidth ? '100%' : 'fit-content'};
  width: 100%;

  ${
    $direction === 'horizontal'
      ? horizontalTabStyle(theme)
      : verticalTabStyle(theme)
  }
`
)

type TabListItemProps = RequiredPickMap<TabsProps, 'direction'>

const horizontalTabItemStyle = (theme: ThemeProps) => `
  border-bottom: ${theme.spacing.mini} solid transparent;
  display: flex;
  justify-content: center;
  padding: ${theme.spacing.large};
  white-space: nowrap;
  width: fit-content;

  &[aria-current='page'] {
    border-bottom: ${theme.spacing.mini} solid ${theme.colors.green.primary};
  }
  
  &:hover {
    border-bottom: ${theme.spacing.mini} solid ${theme.colors.grey.light3};
  }

  ${MEDIA_QUERIES(theme).small}{
    min-width: 10.9rem;
  }
`

const verticalTabItemStyle = (theme: ThemeProps) => `
  border-left: ${theme.spacing.mini} solid transparent;
  padding: ${theme.spacing.default};

  &[aria-current='page'] {
    border-left: ${theme.spacing.mini} solid ${theme.colors.green.primary};
  }

  &:hover {
    border-left: ${theme.spacing.mini} solid ${theme.colors.grey.light3};
  }
`

export const TabListItem = styled.a<TabListItemProps>(
  ({ theme, $direction }) => `
  ${TRANSITION_STYLE}
  ${styleFromTypography(theme.typography.body.medium)}
  align-items: center;
  color: ${theme.colors.grey.light1};
  display: flex;
  flex-direction: row;
  font-family: inherit;
  font-weight: ${theme.fontWeight.regular};
  text-decoration: none;

  ${
    $direction === 'horizontal'
      ? horizontalTabItemStyle(theme)
      : verticalTabItemStyle(theme)
  }
  
  &[aria-current='page'] {
    color: ${theme.colors.grey.primary};
    font-weight: ${theme.fontWeight.medium};
  }

  &:focus {
    border-radius: ${theme.borderRadius.small};
    box-shadow: 0 0 ${theme.spacing.mini} #5A84DF;
    outline: .1rem solid ${theme.colors.blue.primary};
  } 
`
)
