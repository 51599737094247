import { SRP_LISTING_LIMIT } from '@/constants/pageSettings'

import { getPageOffset } from '../getPageOffset'

export const getPaginationFromUrl = (url: string): { limit: number; offset: number } => {
  const pageNumberRegex = /\/page-(\d+)/
  const match = pageNumberRegex.exec(url)
  const currPage = match && match[1] ? parseInt(match[1], 10) : null

  if (!currPage) {
    return { limit: SRP_LISTING_LIMIT, offset: 0 }
  }

  return { limit: SRP_LISTING_LIMIT, offset: getPageOffset(currPage, SRP_LISTING_LIMIT) }
}
