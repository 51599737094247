import { type ALL_SIZES, type EXTENSION, getImageBySize } from '@/utils/kijiji-common/image'

// In the current version of next.js, this is not exported so we have to define it ourselves
export type ImageConfig = {
  allSizes: number[]
  deviceSizes: number[]
  imageSizes: number[]
  path: string
  loader: string
}

export declare type ImageLoaderProps = {
  config?: Partial<Readonly<ImageConfig>>
  src: string
  imageWidth?: ALL_SIZES
  quality?: number
}

export type CustomImageLoaderProps = ImageLoaderProps & {
  extension?: EXTENSION
  config?: ImageConfig
}

/**
 * Returns a custom image loader for Next.js `Image` component
 *
 */
export const imageLoader = ({
  src,
  imageWidth = 200,
  extension = 'WEBP',
}: CustomImageLoaderProps) => getImageBySize(src, imageWidth, extension, true)
