import { BreakpointKeys, breakpoints as _breakpoints } from '@kijiji/theme'

/**
 * This function returns the closest breakpoint key the window width is smaller than or
 * equal to
 *
 * @returns BreakpointKeys - the current breakpoint key based on the window width
 */
export const getWindowWidthBreakpointKey = () => {
  const screenWidth = window.innerWidth
  const breakpoints = Object.entries(_breakpoints)
  const currentBreakpoint = breakpoints.find(
    ([, width]) => screenWidth <= width
  )
  const currentBreakpointKey = currentBreakpoint?.[0] ?? 'xLarge'
  return currentBreakpointKey as BreakpointKeys
}
