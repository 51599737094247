import styled from 'styled-components'

import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { BodyText } from '@/ui/atoms/body-text'
import { Button } from '@/ui/atoms/button'
import { Flex } from '@/ui/atoms/flex'
import { ListItem } from '@/ui/atoms/list-item'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'
import { FormControlInput } from '@/ui/molecules/form-control'
import { List } from '@/ui/molecules/list'
import { Modal, ModalBody, ModalFooter } from '@/ui/molecules/modal'
import mapPlaceholderImage from '~/public/next-assets/images/map-placeholder.webp'

import { type MapControlButtonProps } from './MapControlButton'

/* Spacing is hard-coded to match legacy styles */
export const RegionCheckboxWrapper = styled.div(
  ({ theme }) => `
  position: absolute;
  padding: 1rem 1.2rem;
  bottom: 1rem;
  left: 1rem;
  background-color: ${theme.colors.white};

  label {
    color: ${theme.colors.grey.light1};
    ${styleFromTypography(theme.typography.body.small)}
  }
`
)

/* A custom width was specified to match legacy */
export const StyledModal = styled(Modal)(
  ({ theme }) => `
  height: 100vh;
  padding: 0;
  width: 100vw;

  ${MEDIA_QUERIES(theme).medium} {
    height: auto;
    width: 68rem;
  }
  `
)

/* A custom min-height was specified to match legacy */
export const StyledModalBody = styled(ModalBody)(
  ({ theme }) => `
  flex: 1;
  position: relative;

  background-image: url(${mapPlaceholderImage.src});
  background-size: cover;

  ${MEDIA_QUERIES(theme).medium} {
    height: fit-content;
    min-height: 35rem;
  }
`
)

/* Location Modal Footer */
export const StyledModalFooter = styled(ModalFooter)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  margin: 2rem;
  padding: 0;
  gap: 3rem;

  ${MEDIA_QUERIES(theme).medium} {
    flex-direction: row;
  }
`
)

export const SliderGroupWrapper = styled(Flex)(
  ({ theme }) => `
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${theme.spacing.default};

  ${MEDIA_QUERIES(theme).medium}  {
    flex-direction: row;
  }
`
)

/* A custom margin-right was specified to match legacy */
export const SliderWrapper = styled.div`
  flex: 1;
  width: 100%;
`

export const RadiusUnitsWrapper = styled(Flex)(
  ({ theme }) => `
  ${MEDIA_QUERIES(theme).medium} {
    padding-right: ${theme.spacing.default}
  }
`
)

export const ConfirmButton = styled(Button)(
  ({ theme }) => `
  width: 100%;

  ${MEDIA_QUERIES(theme).medium}{
    width: fit-content;
  }
`
)

/* Location Modal Map */
type MapAnchorDivProps = {
  /**
   * Conditionally shows/hides map
   */
  $isVisible: boolean
  /**
   * Condiditonally blur the map
   */
  $isBlurred: boolean
}

export const MapAnchorDiv = styled.div<MapAnchorDivProps>(
  ({ $isVisible, $isBlurred }) => `
  -webkit-filter: ${$isBlurred ? 'blur(12px)' : ''};
  bottom: 0;
  display: ${$isVisible ? 'block' : 'none'};
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`
)

/* Location Modal Search Bar */
export const LocationSearchBarWrapper = styled.div`
  margin: 2rem 2rem auto 2rem;
  position: relative;
  z-index: ${zIndexRegistry.LOCATION_MODAL_SEARCH_BAR};
`

type StyledFormControlLocationInputProps = { $isFocused: boolean }

export const StyledFormControlLocationInput = styled(
  FormControlInput
)<StyledFormControlLocationInputProps>(
  ({ $isFocused, theme }) => `
  border: 0.1rem solid ${theme.colors.grey.light3};
  box-shadow: 0 0 .8rem rgba(38, 129, 219, 0.5);
  min-width: 100%;
  overflow: hidden;
  color: ${theme.colors.grey.primary};
  font-weight: ${theme.fontWeight.regular};

  ${$isFocused && `border-color: ${theme.colors.blue.primary};`}
  `
)

export const SystemMessageWrapper = styled.div(
  ({ theme }) => `
  left: 10%;
  position: absolute;
  right: 10%;
  top: 9rem;
  width: 80%;
  z-index: 310;

  > div {
    margin-bottom: ${theme.spacing.default};
  }
`
)

/* A custom box-shadow was specified to match legacy */
export const LocationList = styled(List)<{ isDropdownOpen: boolean }>(
  ({ theme, isDropdownOpen }) => `
  background-color: ${theme.colors.white};
  border-radius: 0 0 ${theme.borderRadius.small} ${theme.borderRadius.small};
  border: 0.1rem solid ${theme.colors.grey.light4};
  border-top: none;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.15);
  display: ${isDropdownOpen ? 'flex' : 'none'};
  max-height: 27.2rem;
  padding: ${theme.spacing.defaultSmall} 0;
  overflow-y: auto;
  row-gap: 0;
`
)

type LocationListItemProps = { $isHighlighted: boolean }

export const LocationListItem = styled(ListItem)<LocationListItemProps>(
  ({ theme, $isHighlighted }) => `
  background-color: ${$isHighlighted ? theme.colors.grey.light4 : theme.colors.white};
  cursor: pointer;
  min-height: 4.2rem;
  padding: 0 ${theme.spacing.default};

  span p {
    color: ${theme.colors.grey.primary};

    span {
      padding: ${theme.spacing.mini} ${theme.spacing.defaultSmall};
    }
  }
  
  > div {
    max-height: 3.2rem;
    max-width: 3.2rem;
  }
}
`
)

export const LocationListItemBadge = styled.span(
  ({ theme }) => `
  background-color: ${theme.colors.purple.light2};
  border-radius: ${theme.borderRadius.small};
  color: ${theme.colors.white};
  ${styleFromTypography(theme.typography.body.xSmall)}
  padding: ${theme.spacing.mini} ${theme.spacing.defaultSmall};
  margin-left: ${theme.spacing.defaultSmall};
  text-transform: uppercase;
`
)

export const LocationListTitle = styled(BodyText)(
  ({ theme }) => `
  color: ${theme.colors.grey.primary};
  font-weight: ${theme.fontWeight.medium};
  margin: ${theme.spacing.defaultSmall} ${theme.spacing.defaultSmall} ${theme.spacing.defaultSmall} ${theme.spacing.default};
`
)

// eslint-disable-next-line @kijiji/prefer-button-component
export const MapControlButtonBase = styled.button<Pick<MapControlButtonProps, 'isActive'>>(
  ({ theme, isActive }) => `
  background-color: ${theme.colors.purple.light2};
  padding: ${theme.spacing.defaultSmall};
  box-shadow: 2px 2px 4px 0px #3E41531A;
  border-radius: ${theme.borderRadius.small};
  margin-right: 1rem;

  &:hover {
    background-color: ${theme.colors.purple.light1};
  }

  &:active {
    background-color: ${theme.colors.purple.dark1};
  }

  ${isActive ? `background-color: ${theme.colors.purple.primary};` : ''}
`
)
