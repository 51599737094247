import EditIcon from '@kijiji/icons/src/icons/Edit'
import MykijijiIcon from '@kijiji/icons/src/icons/Mykijiji'
import NewIcon from '@kijiji/icons/src/icons/New'
import { ChangeEventHandler, useRef } from 'react'

import {
  AvatarContainer,
  AvatarImage,
  UploadImageButton,
  UploadImageInput,
} from './styled'

export type AvatarProps = {
  /**
   * The alt-text to use for the image
   */
  imageAltText: string
  /**
   * An event handler that is called when the avatar image is changed
   */
  onAvatarSelected?: ChangeEventHandler<HTMLInputElement>
  /**
   * Whether the avatar image should be editable
   * @default false
   */
  isEditMode?: boolean
  /**
   * The URL of the image to display
   */
  imageUrl?: string
  /**
   * An ID to apply to the image input
   * @default 'avatar-upload'
   */
  id?: string
  /**
   * The aria label for the image upload button
   */
  btnLabel: string
}

export const Avatar = ({
  btnLabel,
  imageAltText,
  imageUrl,
  id,
  isEditMode = false,
  onAvatarSelected,
}: AvatarProps) => {
  const inputFile = useRef<HTMLInputElement>(null)
  const inputId = id || 'avatar-upload'

  const openDialog = () => {
    if (inputFile.current !== null) {
      inputFile.current.click()
    }
  }

  return (
    <AvatarContainer hasImage={!!imageUrl} data-testid={`avatar-${inputId}`}>
      {imageUrl ? (
        <AvatarImage src={imageUrl} alt={imageAltText} />
      ) : (
        <MykijijiIcon size="md" />
      )}

      {isEditMode && (
        <>
          <UploadImageButton
            aria-label={btnLabel}
            type="button"
            onClick={openDialog}
          >
            {imageUrl ? (
              <EditIcon size="xxsm" aria-hidden />
            ) : (
              <NewIcon aria-hidden />
            )}
          </UploadImageButton>
          <UploadImageInput
            data-testid={`file-input-${inputId}`}
            id={inputId}
            onChange={onAvatarSelected}
            ref={inputFile}
            type="file"
          />
        </>
      )}
    </AvatarContainer>
  )
}
