import { HTMLAttributes } from 'react'

import { StyledAs } from '@/ui/typings/helpers'

import { GridContainer } from './styled'

type GridProps = { as?: StyledAs } & HTMLAttributes<HTMLDivElement>

export const Grid = ({ children, ...rest }: GridProps) => (
  <GridContainer {...rest}>{children}</GridContainer>
)
