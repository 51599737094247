import styled from 'styled-components'

export const STANDARD_AVATAR_SIZE = '3.2rem'

export const Avatar = styled.div(
  ({ theme, color }) => `
  align-items: center;
  border-radius: ${theme.borderRadius.rounded};
  color: ${theme.colors.purple.light1};
  display: flex;
  font-size: ${theme.spacing.default};
  height: ${STANDARD_AVATAR_SIZE};
  justify-content: center;
  overflow: hidden;
  user-select: none;
  width: ${STANDARD_AVATAR_SIZE};
  background: ${color};
`
)

export const AvatarImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
`
