import Image, { type ImageProps } from 'next/legacy/image'
import { useEffect, useState } from 'react'

import { type ALL_SIZES, getImageBySize } from '@/utils/kijiji-common/image'

import { type CustomImageLoaderProps, imageLoader } from './utils'

export type ImageCustomProps = Omit<ImageProps, 'src'> &
  CustomImageLoaderProps & {
    width?: ALL_SIZES
    srcFallback?: string
  }

/**
 * A custom wrapper around Next.js Image component to provide support for custom image formats and sizes
 *
 * @example
 *  // Use ImageCustom like you would use Next.js Image component:
 *  <ImageCustom src="/path/to/image.jpg" alt="An image" width={200} extension="WEBP" />
 *
 * @param {ImageProps & CustomImageLoaderProps} props - The props to pass to the ImageCustom component
 * @returns {ReactElement} The rendered ImageCustom component
 */
export const ImageCustom: React.FC<ImageCustomProps> = ({
  src,
  width,
  extension,
  srcFallback = '/next-assets/images/not-found.jpg',
  ...rest
}) => {
  const [fallbackSrc, setFallbackSrc] = useState(src || srcFallback)

  useEffect(() => {
    // if the src is not defined, we use the fallback image
    if (!src) {
      setFallbackSrc(srcFallback)
    } else {
      setFallbackSrc(src)
    }
  }, [src, srcFallback])

  const loader = imageLoader

  const modifiedProps: ImageProps = {
    src: getImageBySize(fallbackSrc, width ?? 200, extension, true),
    loader,
    onError: () => setFallbackSrc(srcFallback),
    ...rest,
  }

  if (!src) {
    // when using the fallback image, we don't need to optimize it for the different sizes
    modifiedProps.unoptimized = true
    modifiedProps.width = undefined
    modifiedProps.height = undefined
    modifiedProps.layout = 'fill'
  }

  // eslint-disable-next-line jsx-a11y/alt-text
  return <Image {...modifiedProps} />
}
