import styled from 'styled-components'

import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

import { GridItemProps } from './GridItem'

export const GridContainer = styled.div(
  ({ theme }) => `
  display: grid;
  grid-gap: ${theme.spacing.default};
  grid-template-columns: repeat(8, 2.7rem);
  justify-content: center;
  margin: 0 ${theme.spacing.default};
  max-width: 100%;

  ${MEDIA_QUERIES(theme).medium} {
    grid-template-columns: repeat(16, ${theme.spacing.xLarge});
    margin: 0 ${theme.spacing.defaultSmall};
  }

  ${MEDIA_QUERIES(theme).xLarge} {
    grid-gap: ${theme.spacing.large};
    grid-template-columns: repeat(16, 5.6rem);
    margin: 0 ${theme.spacing.default};
  }
`
)

export const GridItemContainer = styled.div<GridItemProps>(
  ({
    alignSelf = 'start',
    columnEnd = 'unset',
    columnStart = 'unset',
    gridArea = 'unset',
    justifySelf = 'start',
    rowEnd = 'unset',
    rowStart = 'unset',
  }) => `
  align-self: ${alignSelf};
  grid-area: ${gridArea};
  grid-column-end: ${columnEnd};
  grid-column-start: ${columnStart};
  grid-row-end: ${rowEnd};
  grid-row-start: ${rowStart};
  justify-self: ${justifySelf};
  width: 100%;
`
)
