import { useDecision } from '@optimizely/react-sdk'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { type FC, type ReactNode, useCallback, useEffect, useState } from 'react'

import {
  type LocationErrorType,
  DEFAULT_CLOSE_TO_ME_RADIUS,
  LOCATION_ERROR_TYPE,
  RADIUS_SLIDER_HALF_POINT_KMS,
} from '@/constants/location'
import { areLocationsEquivalent } from '@/domain/location/areLocationsEquivalent'
import { setModifiedRadiusLocalStorage } from '@/domain/location/setModifiedRadiusLocalStorage'
import { type UserLocation } from '@/generated/graphql-types'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { useSearchActions } from '@/hooks/srp/useSearchActions'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { FEATURE_FLAG } from '@/lib/optimizely'
import { isPositiveInteger } from '@/utils/kijiji-common/number'

import { LocationModalFooter } from './LocationModalFooter'
import { LocationModalHeader } from './LocationModalHeader'
import { LocationSearchBar } from './LocationSearchBar'
import { Map } from './Map'
import { RegionCheckbox } from './RegionCheckbox'
import { LocationSearchBarWrapper, StyledModal, StyledModalBody } from './styled'
import { useLiveLocation } from './useLiveLocation'

export type SearchLocationModalProps = {
  /**
   * Passes function to open the modal to children
   */
  children: (openModal: () => void) => ReactNode
}

export const SearchLocationModal: FC<SearchLocationModalProps> = ({ children }) => {
  const { pathname } = useRouter()
  const { refetchResults } = useSearchActions()

  const { t } = useTranslation(['common', 'global_header'])
  const { location: userLocation, updateUserLocation } = useGetLocation()
  const { getLiveLocation } = useLiveLocation()
  const [localLocation, setLocalLocation] = useState<UserLocation>(userLocation)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [searchByRegionCheckboxValue, setSearchByRegionCheckboxValue] = useState<boolean>(false)
  const [activeSearchBarError, setSearchBarActiveError] = useState<LocationErrorType | undefined>(
    undefined
  )
  const [{ isUserUsingCurrentLocation, isUserUsingNearbyLocation }, setLocationUsageType] =
    useState({
      isUserUsingCurrentLocation: false,
      isUserUsingNearbyLocation: false,
    })
  const [decision] = useDecision(FEATURE_FLAG.SEARCH_AROUND_ME_EXP)
  const isSearchAroundMeExperimentEnabled = decision?.enabled === true
  const isSearchAroundMeEnabled =
    isSearchAroundMeExperimentEnabled && decision?.variationKey === 'b'

  const [isOpen, setIsOpen] = useState<boolean>(false)

  /**
   * TODO: The logic for this modal is defined outside of the Modal Portal
   * The code should be only initialized when the portal opens, instead of when the component renders
   *
   * This is creating a problem with the "localLocation" initialization when the user changes their location
   * after the page has been loaded.
   */
  useEffect(() => {
    setLocalLocation(userLocation)
  }, [userLocation])

  /**
   * Show map if input is not focused, and we are not searching by region
   */
  const showMap = searchByRegionCheckboxValue ? true : !localLocation?.isRegion

  const changeLocation = useCallback(
    (newLocation: UserLocation, source?: string) => {
      setLocalLocation(newLocation)

      if (isSearchAroundMeEnabled) {
        if (isUserUsingCurrentLocation && source !== 'currentLocationButton') {
          setLocationUsageType((prevValue) => ({ ...prevValue, isUserUsingCurrentLocation: false }))
        }

        if (
          isUserUsingNearbyLocation &&
          newLocation.area?.radius &&
          newLocation.area?.radius > DEFAULT_CLOSE_TO_ME_RADIUS
        ) {
          setLocationUsageType((prevValue) => ({ ...prevValue, isUserUsingNearbyLocation: false }))
        }
      }

      setIsLoading(false)
      !newLocation.isRegion && setSearchByRegionCheckboxValue(false)
    },
    [isSearchAroundMeEnabled, isUserUsingCurrentLocation, isUserUsingNearbyLocation]
  )

  const setRegionCheckboxValue = useCallback(
    (newValue: boolean) => {
      setLocalLocation({ ...localLocation, isRegion: newValue })
      setSearchByRegionCheckboxValue(newValue)
    },
    [localLocation]
  )

  const setLoading = useCallback((newValue: boolean) => {
    setIsLoading(newValue)
  }, [])

  const onModalClose = useCallback(
    ({ wasLocationSet }: { wasLocationSet?: boolean }) => {
      if (!wasLocationSet) {
        trackEvent({ action: GA_EVENT.LocationSelectCancel })
        /* Reset the local modal location when user closes the modal without setting a new location */
        setLocalLocation(userLocation)
      }
      setIsOpen(false)
      /* Reset the Search All checkbox state */
      setSearchByRegionCheckboxValue(false)
    },
    [userLocation]
  )

  const setLocation = useCallback(
    (location: UserLocation, userModifiedRadius?: boolean) => {
      if (userLocation && areLocationsEquivalent(userLocation, location)) {
        onModalClose({ wasLocationSet: false })
        return
      }

      /** Update local storage if user has manually modified and submitted a radius value */
      if (userModifiedRadius && !location.isRegion) {
        setModifiedRadiusLocalStorage(true)
      }

      updateUserLocation(location)

      /** Check if user is on SRP */
      if (pathname.includes(`/srp/`)) {
        /** Refetch results for a region location */
        if (location.isRegion && isPositiveInteger(location.id)) {
          refetchResults({ location: { id: location.id } })
        } else {
          /** Refetch results for a radius location */
          refetchResults({ location: { id: location.id, area: location.area } })
        }
      }

      onModalClose({ wasLocationSet: true })
      trackEvent({ action: GA_EVENT.LocationSelect })
    },
    [onModalClose, pathname, refetchResults, updateUserLocation, userLocation]
  )

  const handleClickCloseToMe = async () => {
    setLoading(true)
    const liveLocation = await getLiveLocation()
    setLoading(false)
    if (!liveLocation) {
      setSearchBarActiveError(LOCATION_ERROR_TYPE.LIVE_LOCATION_ERROR)
      return
    }

    const liveLocationWithRadiusSet: UserLocation = {
      ...liveLocation,
      area: {
        ...liveLocation.area,
        radius: DEFAULT_CLOSE_TO_ME_RADIUS,
        latitude: liveLocation.area?.latitude ?? 0,
        longitude: liveLocation.area?.longitude ?? 0,
      },
    }

    trackEvent({
      action: GA_EVENT.LocationCloseToMeClick,
      label: 'btn_pos=map',
    })
    setLocationUsageType({
      isUserUsingCurrentLocation: false,
      isUserUsingNearbyLocation: true,
    })
    changeLocation(liveLocationWithRadiusSet)
  }

  const handleClickUseCurrentLocation = async () => {
    setLoading(true)
    const liveLocation = await getLiveLocation()
    setLoading(false)
    if (!liveLocation) {
      setSearchBarActiveError(LOCATION_ERROR_TYPE.LIVE_LOCATION_ERROR)
      return
    }

    setLocationUsageType({
      isUserUsingCurrentLocation: true,
      isUserUsingNearbyLocation: false,
    })
    changeLocation(liveLocation, 'currentLocationButton')
  }

  const commonLocationModalProps = {
    localLocation,
    onLocationChange: changeLocation,
    searchByRegionCheckboxValue,
  }

  return (
    <>
      {isOpen && (
        <StyledModal
          data-testid="search-location-modal"
          isCentered
          isOpen={isOpen}
          label={t('common:modals.search_location.title')}
          onCancel={() => onModalClose({ wasLocationSet: false })}
        >
          <LocationModalHeader
            handleClose={() => onModalClose({ wasLocationSet: false })}
            title={t('common:modals.search_location.title')}
          />

          <StyledModalBody>
            <LocationSearchBarWrapper>
              <LocationSearchBar
                {...commonLocationModalProps}
                id="select-location-search-bar"
                label={t('modals.search_location.labels.search_input')}
                isLoadingMap={isLoading}
                onLocationSubmit={setLocation}
                setLoading={setLoading}
                activeError={activeSearchBarError}
                setActiveError={setSearchBarActiveError}
                setLocationUsageType={setLocationUsageType}
              />
            </LocationSearchBarWrapper>

            <Map
              shouldShowMap={showMap}
              isRegion={!!localLocation.isRegion}
              latitude={localLocation.area?.latitude}
              longitude={localLocation.area?.longitude}
              radius={localLocation.area?.radius || RADIUS_SLIDER_HALF_POINT_KMS}
              onLocationChange={changeLocation}
              onClickCloseToMe={handleClickCloseToMe}
              onClickUseCurrentLocation={handleClickUseCurrentLocation}
              setLocationUsageType={setLocationUsageType}
              isUserUsingCurrentLocation={isUserUsingCurrentLocation}
              isUserUsingNearbyLocation={isUserUsingNearbyLocation}
              isSearchAroundMeEnabled={isSearchAroundMeEnabled}
            />

            <RegionCheckbox
              {...commonLocationModalProps}
              setRegionCheckboxValue={setRegionCheckboxValue}
              showMap={showMap}
              setLoading={setLoading}
            />
          </StyledModalBody>

          <LocationModalFooter {...commonLocationModalProps} onLocationSubmit={setLocation} />
        </StyledModal>
      )}
      {children(() => setIsOpen(true))}
    </>
  )
}
