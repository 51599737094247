import { forwardRef, TextareaHTMLAttributes } from 'react'

import { CommonInputProps } from '@/ui/typings/commonTextInput'

import { StyledTextArea } from './styled'

export type TextAreaProps = CommonInputProps &
  TextareaHTMLAttributes<HTMLTextAreaElement>

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ errorId, id, ...rest }: TextAreaProps, ref) => {
    return (
      <StyledTextArea
        aria-describedby={errorId}
        aria-invalid={!!errorId}
        data-testid={`text-area-${id}`}
        id={id}
        ref={ref}
        {...rest}
      />
    )
  }
)

TextArea.displayName = 'TextArea'
