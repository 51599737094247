import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { HeadlineText } from '@/ui/atoms/headline-text'
import { ModalCloseButton, ModalHeader } from '@/ui/molecules/modal'

export type LocationModalHeaderProps = {
  title: string
  handleClose: () => void
}

export const LocationModalHeader: FC<LocationModalHeaderProps> = ({ handleClose, title }) => {
  const { t } = useTranslation('common')
  const theme = useTheme()

  return (
    <ModalHeader>
      <HeadlineText color={theme.colors.purple.primary} size="small" textAlign="center">
        {title}
      </HeadlineText>

      <ModalCloseButton
        onClick={handleClose}
        aria-label={`${t('modals.close.button.label')}`}
        data-testid="close-location-modal-button"
      />
    </ModalHeader>
  )
}
