import styled from 'styled-components'

import { Card } from '@/ui/atoms/card'
import { Divider } from '@/ui/atoms/divider'
import { Flex } from '@/ui/atoms/flex'
import { RoundedChip } from '@/ui/atoms/rounded-chip'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const OverviewBody = styled(Flex)(
  ({ theme }) => `
  font-size: ${theme.typography.body.medium.fontSize};
  margin-top: ${theme.spacing.default};

  ${MEDIA_QUERIES(theme).medium}{
    margin-top: ${theme.spacing.large};
  }
`
)

type OverviewSectionProps = {
  isLastSectionWithNoFeatures: boolean
}

export const OverviewSection = styled(Card)<OverviewSectionProps>(
  ({ theme, isLastSectionWithNoFeatures }) => `

  flex: 50%;
  padding: 0;
  border: none;
  
  ${
    isLastSectionWithNoFeatures
      ? ''
      : `border-bottom: 0.1rem solid ${theme.colors.grey.light4}; 
        padding-bottom: ${theme.spacing.default};`
  }

  ${MEDIA_QUERIES(theme).medium}{
    flex: 34%;
    padding: ${theme.spacing.default};
    border: 0.1rem solid ${theme.colors.grey.light4};
  }

`
)

export const OverviewSectionHeaderText = styled(Flex)(
  ({ theme }) => `

  margin-left: ${theme.spacing.default};
`
)

export const AttributeName = styled.span(
  ({ theme }) => `;
  font-weight: ${theme.fontWeight.medium};
  line-height: ${theme.spacing.large};
`
)

export const AttributeValue = styled.span(
  ({ theme }) => `
  color: ${theme.colors.grey.light1};
  line-height: ${theme.spacing.large};
`
)

type OverviewSectionAttributesProps = {
  isSingleItem: boolean
}
export const OverviewSectionAttributes =
  styled.ul<OverviewSectionAttributesProps>(
    ({ theme, isSingleItem }) => `
  margin-top: ${theme.spacing.default};
  list-style-type: none;
  padding: 0;


   
  ${MEDIA_QUERIES(theme).medium}{
    ${isSingleItem ? `columns: 2` : ''}
  }

`
  )

export const OverviewAttributeContainer = styled.li(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.defaultSmall};

  :last-child {
    margin-bottom: 0;
  }
`
)

export const OverviewFeatures = styled.ul(
  ({ theme }) => `
  display: inline-flex;
  flex-wrap: wrap;
  padding: 0;
  margin-top: ${theme.spacing.default}
`
)

export const FeatureChipListItem = styled.li(
  ({ theme }) => `
  list-style-type: none;

  & > div {
    display: flex;
    align-items: center;

    & > span {
      height: ${theme.spacing.large};
      display: grid;
      place-items: center;
    }
  }
`
)

const OverviewFeaturesViewMoreButtonShadow = '0px 0px 8px 0px #2681DB80'
export const OverviewFeaturesViewMoreButton = styled(RoundedChip)(
  ({ theme }) => `
  width: ${theme.spacing.xLarge};
  height: ${theme.spacing.xLarge};
  margin-top: ${theme.spacing.defaultSmall};
  padding: ${theme.spacing.mini} calc(${theme.spacing.mini}/2);

  display: flex;
  align-items: center;
  justify-content: center;

  border-width: 1px;
  border-color: ${theme.colors.purple.light4};
  background-color: ${theme.colors.purple.light5};

  &:hover {
    background-color: ${theme.colors.purple.light4};
  }

  &:focus {
    background-color: ${theme.colors.purple.light4};
    border-color: ${theme.colors.blue.primary};
    box-shadow: ${OverviewFeaturesViewMoreButtonShadow};
  }

  &:active {
    background-color: ${theme.colors.purple.light2};
    border-color: ${theme.colors.purple.light4};
    box-shadow: unset;

    & > p {
      color: ${theme.colors.white};
    }
  }
`
)

export const OverviewFooterAttributes = styled.ul(
  ({ theme }) => `
  padding: 0;
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing.default} 0 0 0;

  ${MEDIA_QUERIES(theme).medium}{
    flex-direction: row;
  }
`
)

export const OverviewFooterAttributeContainer = styled.li(
  ({ theme }) => `

  margin-right: ${theme.spacing.xLarge};
  margin-bottom: ${theme.spacing.defaultSmall};
  list-style-type: none;
  
  :last-child {
    margin-bottom: 0;
  }
  
  ${MEDIA_QUERIES(theme).medium}{
     margin-bottom: 0;
  }
`
)

export const OverviewFooterDivider = styled(Divider)(
  ({ theme }) => `

  // to get the divider to extend past the padding of the card:
  // width should be 100% + 2x padding of the card.

  width: calc(100% + ${parseFloat(theme.spacing.large) * 2}rem);
  margin: 0 -${theme.spacing.large};
  background-color: ${theme.colors.grey.light4};
  border: none;
  margin-top: ${theme.spacing.large}
`
)

export const FeatureSectionRow = styled(Flex)(
  ({ theme }) => `
    flex-direction: column;

    ${MEDIA_QUERIES(theme).medium}{
      flex-direction: row;
    }
`
)
