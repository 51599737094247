import { useRouter } from 'next/router'
import { Trans, useTranslation } from 'next-i18next'
import { useCallback, useEffect, useRef, useState } from 'react'

import { getLegacyMessage } from '@/constants/legacyMessages'
import {
  type SystemMessage as SystemMessageType,
  CIS_ERROR_MESSAGES,
  SYSTEM_MESSAGES,
} from '@/constants/systemMessages'
import { trackEvent } from '@/lib/ga'
import { SystemMessage } from '@/ui/molecules/system-message'
import { isString } from '@/utils/types'

import { LinkCustom } from '../link-custom/LinkCustom'
import { GlobalMessagesContainer } from './styled'

export const GlobalMessages = () => {
  const isFirstRender = useRef<boolean>(true)
  const [messages, setMessages] = useState<SystemMessageType[]>([])
  const { query, replace, pathname } = useRouter()
  const { t } = useTranslation('common')

  const getSystemMessage = useCallback(
    (qs: string): SystemMessageType | null => {
      const value = query[qs]?.toString()
      if (!value) return null

      const cisMessage = qs === 'error' ? CIS_ERROR_MESSAGES[value] : null
      if (cisMessage) {
        return cisMessage
      }

      const message = value === 'true' ? SYSTEM_MESSAGES[qs] : null
      return message
    },
    [query]
  )

  useEffect(() => {
    /** Prevent useEffect from triggering query GA events multiple times */
    if (!isFirstRender.current) return

    const systemMessages: SystemMessageType[] = []
    const newUrl = new URL(global.window.location.href)

    const { messageKey, messageClass } = query
    if (isString(messageClass) && isString(messageKey)) {
      const legacyMessage = getLegacyMessage(messageKey, messageClass)
      if (!legacyMessage) {
        return
      }

      systemMessages.push(legacyMessage)
      delete query.messageKey
      delete query.messageClass
    }

    const cleanedQuery = { ...query }
    newUrl.searchParams.delete('messageKey')
    delete cleanedQuery.messageKey

    newUrl.searchParams.delete('messageClass')
    delete cleanedQuery.messageClass

    for (const key in query) {
      const systemMessage = getSystemMessage(key)
      if (!systemMessage) {
        continue
      }

      systemMessages.push(systemMessage)
      if (systemMessage?.gaEvent) {
        key === 'error'
          ? trackEvent({ action: systemMessage.gaEvent, label: `error_type=${systemMessage.key}` })
          : trackEvent({ action: systemMessage.gaEvent })
      }

      newUrl.searchParams.delete(systemMessage.key)
      delete cleanedQuery[systemMessage.key]
    }

    if (systemMessages.length === 0) return

    isFirstRender.current = false
    setMessages(systemMessages)

    replace({ pathname, query: cleanedQuery }, newUrl.href)
  }, [query, getSystemMessage, pathname, replace])

  const onCloseClick = (messageKey: string) => {
    /** Update messages to be shown */
    setMessages((prev) => prev.filter((item) => item.key !== messageKey))
  }

  const getDescription = (messageKey: string, descriptionKey?: string, links?: string[]) => {
    if (!descriptionKey) return ''

    /** If there are links in the description - should interpolate those */
    if (links?.length) {
      return (
        <Trans
          i18nKey={`common:${descriptionKey}`}
          components={links.map((linkKey, idx) => (
            <LinkCustom
              key={`${messageKey}-link-${idx}`}
              variant="secondary"
              href={`${t(linkKey)}`}
              noChildren={true}
            />
          ))}
        />
      )
    }
    /** If there are no links - pass the key to be translated */
    return t(descriptionKey)
  }

  if (!messages.length) return null

  return (
    <GlobalMessagesContainer id="global-messages" data-testid="InfoMessageContainer">
      {messages.map((message) => {
        const { titleKey, descriptionKey, variation, links } = message

        return (
          <SystemMessage
            key={titleKey}
            title={`${t(titleKey)}`}
            closeButtonLabel={t('system_messages.close_label')}
            description={getDescription(message.key, descriptionKey, links)}
            variation={variation}
            onClose={() => onCloseClick(message.key)}
          />
        )
      })}
    </GlobalMessagesContainer>
  )
}
