import styled from 'styled-components'

import { RatingProps } from './Rating'

type RatingContainerProps = {
  $size: RatingProps['size']
}

export const RatingContainer = styled.div<RatingContainerProps>(({ $size }) => {
  const spacing = $size === 'large' ? '0' : '0.7rem'

  return `
  display: flex;
  flex-direction: ${$size === 'large' && 'column'};
  justify-content: center;
  align-items: center;

  p {
    margin-right: ${spacing};
  }

  a {
    margin-left: ${spacing};
  }
`
})
