import styled from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

import { Modal } from '../../modal'

export const AuthModalPanel = styled.div(
  ({ theme }) => `
    background: ${theme.colors.white};
    border-radius: ${theme.borderRadius.medium};
    display: flex;
    flex-direction: column;
  `
)

export const StyledModal = styled(Modal)(
  ({ theme }) => `
    display: grid;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    
    ${MEDIA_QUERIES(theme).medium} {
      height: 51.6rem;
      margin: 10vh auto;
      width: 40rem;
    }
  `
)

export const AuthModalHeader = styled.div(
  ({ theme }) => `
  align-items: center;
  display: grid;
  grid-template-columns: 1fr ${theme.spacing.xLarge};
  margin: ${theme.spacing.default};
`
)

// eslint-disable-next-line @kijiji/prefer-button-component
export const CloseModalButton = styled.button(
  ({ theme }) => `
    align-items: center;
    background: ${theme.colors.grey.light4};
    border-radius: ${theme.borderRadius.rounded};
    display: flex;
    grid-column: 2;
    grid-row: 1;
    height: ${theme.spacing.large};
    justify-content: center;
    width: ${theme.spacing.large};
`
)

export const AuthModalIconContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 4.2rem;
  padding-top: 4.8rem;
`

export const AuthModalTitle = styled(BodyText)(
  ({ theme }) => `
  font-size: 2.2rem;
  padding-bottom: ${theme.spacing.defaultSmall};
  text-align: center;
`
)

export const AuthModalContent = styled.div(
  ({ theme }) => `
    color: ${theme.colors.grey.light2};
    font-size: ${theme.spacing.default};
    padding-left: 4.4rem;
    padding-right: 4.4rem;
    text-align: center;
`
)

export const AuthModalFooter = styled.div(
  ({ theme }) => `
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: ${theme.spacing.default};
  padding-bottom: 4.8rem;
  padding-left: 4.4rem;
  padding-right: 4.4rem;
  padding-top: ${theme.spacing.large};
`
)

export const AuthModalRegisterMessage = styled.p(
  ({ theme }) => `
    color: ${theme.colors.grey.light2};
    padding-top: ${theme.spacing.large};
  `
)
