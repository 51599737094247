import { FC, HTMLAttributes } from 'react'

import { IconChipComponent } from './styled'

export type IconChipProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * The icon for the chip
   */
  icon: React.ReactNode
  /**
   * The text for the card
   */
  text: string | React.ReactElement
}

export const IconChip: FC<IconChipProps> = ({ icon, text, ...props }) => (
  <IconChipComponent {...props}>
    {icon}
    <span>{text}</span>
  </IconChipComponent>
)
