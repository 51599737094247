import HiddenIcon from '@kijiji/icons/src/icons/Hidden'
import VisibleIcon from '@kijiji/icons/src/icons/Visible'

import { BodyText } from '@/ui/atoms/body-text'

import { StyledPasswordVisibilityButton } from './styled'

export type PasswordVisibilityButtonProps = {
  /**
   * Defines if password should be shown
   */
  isPasswordVisible: boolean
  /**
   * Toggle password visibility
   */
  onClick: () => void
  /**
   * Text to be rendered when password is shown
   */
  passwordVisibleText: string
  /**
   * Text to be rendered when password is hidden
   */
  passwordHiddenText: string
}

/**
 * @description Button placed inside of input to show or hide password
 */

export const PasswordVisibilityButton = ({
  isPasswordVisible,
  onClick,
  passwordHiddenText,
  passwordVisibleText,
}: PasswordVisibilityButtonProps) => {
  return (
    <StyledPasswordVisibilityButton
      data-testid="password-visibility"
      onClick={onClick}
      type="button"
    >
      {isPasswordVisible ? (
        <HiddenIcon aria-hidden size="xsm" />
      ) : (
        <VisibleIcon aria-hidden size="xsm" />
      )}

      <BodyText as="span" size="small">
        {isPasswordVisible ? passwordVisibleText : passwordHiddenText}
      </BodyText>
    </StyledPasswordVisibilityButton>
  )
}
