import SuccessIcon from '@kijiji/icons/src/icons/Success'
import styled from 'styled-components'

import { INPUT_ERROR_COLOR } from '@/ui/constants/commonFormStyle'

export const RequirementsBarSuccessIcon = styled(SuccessIcon).withConfig({
  shouldForwardProp: (prop) => !['completed'].includes(prop),
})<{
  completed: boolean
}>`
  height: 1.4rem;
  width: 1.4rem;
  display: ${(props) => (props.completed ? 'inline-block' : 'none')};
`

type RequirementsBarProps = {
  isDirty: boolean
  completed: boolean
}

export const RequirementsBarIncomplete = styled.span<RequirementsBarProps>(
  ({ theme, isDirty, completed }) => `
  border-radius: ${theme.borderRadius.rounded};
  border: 1px solid ${
    isDirty ? INPUT_ERROR_COLOR(theme) : theme.colors.grey.light2
  };
  height: 1.4rem;
  width: 1.4rem;
  display: ${completed ? 'none' : 'inline-block'}
`
)

export const RequirementsBarRequirements = styled.div(
  ({ theme }) => `
  display: flex;
  flex-wrap: wrap;
  margin-top: ${theme.spacing.defaultSmall};
  width: 100%;

  & p {
    align-items: center;
    color: ${theme.colors.grey.light1};
    display: flex;
    margin-right: ${theme.spacing.defaultSmall};

    & svg,
    & span {
      margin-right: ${theme.spacing.mini};
    }
  }
`
)
