import { RADIUS_SLIDER_HALF_POINT_KMS } from '@/constants/location'
import { type GetLocationFromPlaceQuery, type UserLocation } from '@/generated'

import { getLocationCoordinates } from './getLocationCoordinates'

/**
 * Parses query result to provide a location object
 * @param queryResult Result of GetLocationFromPlace query
 * @param optionLabel Label of selected option that sent the query
 *
 * @returns Location object without radius
 */
export const getLocationFromPlaceResult = (
  locationFromPlace: GetLocationFromPlaceQuery['locationFromPlace'],
  optionLabel: string
): UserLocation | null => {
  if (!locationFromPlace?.location || !locationFromPlace?.place) return null

  const {
    place: { address, isCountry, isProvince, location: placeLocation },
    location,
  } = locationFromPlace

  const isRegion = isCountry || isProvince
  const coordinates = getLocationCoordinates(isRegion, placeLocation, location)

  return {
    isRegion,
    id: location?.id,
    name: location?.name
      ? { en_CA: location.name.en_CA, fr_CA: location.name.fr_CA }
      : { en_CA: '', fr_CA: '' },
    area:
      coordinates.latitude && coordinates.longitude
        ? {
            latitude: coordinates.latitude,
            longitude: coordinates.longitude,
            radius: RADIUS_SLIDER_HALF_POINT_KMS,
            address: optionLabel?.length ? optionLabel : address || '',
          }
        : null,
  }
}
