import styled from 'styled-components'

import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { Button } from '@/ui/atoms/button'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

const GLOBAL_HEADER_MAX_WIDTH = '128rem'

type ContainerProps = {
  hasChildren?: boolean
  isPSABannerVisible?: boolean
  isHeaderSimplified: boolean
}

export const GlobalHeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  z-index: ${zIndexRegistry.MAIN_MENU};
  width: 100%;
`

export const Container = styled.header<ContainerProps>`
  border-top: ${({ theme, isPSABannerVisible }) =>
    !isPSABannerVisible && `solid 0.5rem ${theme.colors.purple.primary}`};
  display: flex;
  justify-content: center;
  box-shadow: 0 0.2rem 0.5rem -0.2rem rgba(0, 0, 0, 0.1);
  padding: ${({ theme }) => {
    return theme.spacing.defaultSmall
  }};
`

export const GlobalHeaderContainer = styled.div`
  display: flex;
  max-width: ${GLOBAL_HEADER_MAX_WIDTH};
  width: 100%;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.defaultSmall};
`

type WrapperProps = {
  isHeaderSimplified: boolean
}

export const Wrapper = styled.div<WrapperProps>(
  ({ theme, isHeaderSimplified }) => `
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: ${GLOBAL_HEADER_MAX_WIDTH};
  overflow: visible;
  width: 100%;
  gap: ${isHeaderSimplified ? theme.spacing.defaultSmall : 0};

    ${MEDIA_QUERIES(theme).large} {
      flex-wrap: ${isHeaderSimplified ? 'nowrap' : 'wrap'};
    }

`
)

/** LOGO */

export const LogoWrapper = styled.div(
  ({ theme }) => `
  order: 1;
  min-height: 3.3rem;
  min-width: 2.2rem;

  ${MEDIA_QUERIES(theme).medium} {
    margin-right: ${theme.spacing.large};
    min-width: 6.3rem; 
  }
`
)

// eslint-disable-next-line @kijiji/prefer-link-custom
export const KijijiLogoLink = styled.a`
  display: block;

  svg {
    height: 3.3rem;
    width: auto;
  }
`

/** POST AD CTA */

//Modifications have been made to ensure that the NWA and legacy post ad buttons match
export const PostAdButton = styled(Button)(
  ({ theme }) => `
  text-decoration: none;
  font-size: ${theme.spacing.default};
`
)

/** In order to match legacy code, a specific media query breakpoint is used below which isn't present in theme constants. */
/** if in future we want to bring it up to standard, we would need to upgrade both legacy and NWA with theme constants- either small or mediumSmall */
export const LogoContainer = styled.div<{ isDesktop: boolean; isHeaderSimplified?: boolean }>`
  display: ${({ isDesktop }) => (isDesktop ? 'none' : 'block')};

  & svg {
    height: 3.3rem;
    width: ${({ isDesktop }) => (isDesktop ? '6.3rem' : '2.2rem')};
  }

  ${({ isDesktop, isHeaderSimplified }) =>
    isHeaderSimplified
      ? `
    @media (min-width: 450px) {
      display: ${isDesktop ? 'block' : 'none'};
    }
  `
      : `
    @media (min-width: 380px) {
      display: ${isDesktop ? 'block' : 'none'};
    }
  `}
`
