import styled from 'styled-components'

import { IconButtonSize } from '@/ui/atoms/icon-button/IconButton'
import { getButtonSize } from '@/ui/atoms/icon-button/utils'

type StyledIconButtonProps = {
  $size: IconButtonSize
  $isActive?: boolean
}

export const StyledIconButton = styled.button<StyledIconButtonProps>(
  ({ theme, $size, $isActive }) => `
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.grey.light3};
    height: ${getButtonSize($size)}px;
    width: ${getButtonSize($size)}px;
    border-radius: ${theme.borderRadius.rounded};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & > svg {
        color: ${
          $isActive ? theme.colors.purple.primary : theme.colors.grey.light1
        };
    }

    &:hover {
        border: 1px solid ${theme.colors.purple.light4};
        background: ${theme.colors.purple.light5};

        & > svg {
            color: ${theme.colors.purple.primary};
        }
    }

    &:active {
        background: ${theme.colors.purple.light4};
        border: 1px solid ${theme.colors.purple.light4};

        & > svg {
            color: ${theme.colors.purple.primary};
        }
    }
  `
)
