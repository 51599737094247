import { HTMLAttributes } from 'react'

import { ProgressBarCell, ProgressBarContainer } from './styled'

export type ProgressBarProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Name used for aria-label on progress bar
   */
  label: string
  /**
   * Defines how many steps have been concluded
   */
  progress: number
  /**
   * Total steps to be tracked
   */
  total: number
}

/**
 * @description Bar to track progress.
 * It will automatically set the number of cells based on the total and mark the progress as that number changes.
 */

export const ProgressBar = ({
  label,
  progress,
  total,
  ...rest
}: ProgressBarProps) => {
  return (
    <ProgressBarContainer
      aria-label={label}
      aria-valuemax={total}
      aria-valuemin={0}
      aria-valuenow={progress}
      role="progressbar"
      totalCells={total}
      {...rest}
    >
      {[...Array(total)].map((_, index) => {
        const isCompleted = index + 1 <= progress
        const testid = isCompleted ? 'cell-completed' : 'cell'

        return (
          <ProgressBarCell
            completed={isCompleted}
            data-testid={testid}
            key={`progress-bar-${index}`}
          />
        )
      })}
    </ProgressBarContainer>
  )
}
