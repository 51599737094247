import { createRef, FC, Ref, TextareaHTMLAttributes } from 'react'

import { FloatingLabel } from '@/ui/atoms/floating-label'
import { TextArea } from '@/ui/atoms/text-area'
import { createSequentialId } from '@/ui/helpers/createSequentialId'
import { getAriaDescribedByValue } from '@/ui/helpers/getAriaDescribedByValue'
import { FormControl } from '@/ui/molecules/form-control'
import { MaxLengthIndicator } from '@/ui/molecules/max-length-indicator'
import { CommonInputFieldProps } from '@/ui/typings/commonTextInput'

export type TextAreaFieldProps = CommonInputFieldProps &
  TextareaHTMLAttributes<HTMLTextAreaElement>

export const TextAreaField: FC<TextAreaFieldProps> = ({
  bottom,
  error,
  helperText,
  id,
  label,
  maxLength,
  value,
  ...rest
}) => {
  const textAreaRef: Ref<HTMLTextAreaElement> = createRef()

  const sequentialErrorId =
    error && createSequentialId(`${id}-text-area-error-`)()

  const ariaDescribedByValue = getAriaDescribedByValue(
    sequentialErrorId,
    helperText,
    id
  )

  return (
    <FormControl
      bottom={bottom}
      error={error}
      helperText={helperText}
      id={id}
      isFullWidth
      errorId={sequentialErrorId}
    >
      <FloatingLabel hasError={!!error} htmlFor={id} inputRef={textAreaRef}>
        {label}
      </FloatingLabel>

      <MaxLengthIndicator id={id} maxLength={maxLength} value={value}>
        <TextArea
          aria-describedby={ariaDescribedByValue}
          errorId={sequentialErrorId}
          id={id}
          ref={textAreaRef}
          value={value}
          maxLength={maxLength}
          {...rest}
        />
      </MaxLengthIndicator>
    </FormControl>
  )
}
