import { FC, HTMLAttributes } from 'react'

import { Flex } from '@/ui/atoms/flex'
import { StyledAs } from '@/ui/typings/helpers'

import { SectionHeaderText, SectionSubtitle, SectionTitle } from './styled'

export type SectionProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Optional icon for the section header
   */
  icon?: React.ReactNode
  /**
   * Title of the section
   */
  title: string
  /**
   * The type of element you want the title to be rendered in
   * @default "span"
   */
  titleAs?: StyledAs
  /**
   * Optional sub title for the section
   */
  subtitle?: string
}

export const Section: FC<SectionProps> = ({
  children,
  icon,
  title,
  titleAs,
  subtitle,
  ...props
}) => (
  <section>
    <Flex {...props}>
      {icon}
      <SectionHeaderText
        hasIcon={!!icon}
        flexDirection="column"
        justifyContent="center"
      >
        <SectionTitle as={titleAs}>{title}</SectionTitle>
        {subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
      </SectionHeaderText>
    </Flex>
    {children}
  </section>
)
