import styled from 'styled-components'

import { zIndexRegistry } from '@/constants/zIndexRegistry'

export const BaseLayoutBody = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const BaseLayoutWrapper = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.grey.light5};
  position: relative;
  z-index: ${zIndexRegistry.ROOT}; //Required to set the stacking context of the main application container
  `
)
