import CloseIcon from '@kijiji/icons/src/icons/Close'
import { FC, Fragment, HTMLAttributes } from 'react'
import { useTheme } from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'
import { Button } from '@/ui/atoms/button'
import { Divider } from '@/ui/atoms/divider'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'

import {
  DialogActions,
  DialogCloseButton,
  DialogContainer,
  DialogContent,
  DialogListActions,
} from './styled'

type DialogButton = {
  /**
   * Button Label
   */
  label: string
  /**
   * Button onClick
   */
  onClick: () => void
}

export type DialogProps = {
  /**
   * Stack list of action items
   */
  buttonList?: DialogButton[]
  /**
   * Label for close dialog button
   * [Accessibility Requirement]
   */
  closeButtonLabel: string
  /**
   * Action to close dialog
   */
  onCloseRequest: () => void
  /**
   * Dialog primary button
   */
  primaryButton?: DialogButton
  /**
   * Dialog secondary button
   */
  secondaryButton?: DialogButton
  /**
   * Body test of the dialog
   */
  text: string
  /**
   * Main text of the dialog
   */
  title?: string
} & HTMLAttributes<HTMLDivElement>

export const Dialog: FC<DialogProps> = ({
  buttonList,
  closeButtonLabel,
  onCloseRequest,
  primaryButton,
  secondaryButton,
  text,
  title,
}) => {
  const { colors, spacing } = useTheme()

  return (
    <DialogContainer>
      <DialogContent p={spacing.default} pBottom="0">
        <DialogCloseButton
          onClick={onCloseRequest}
          aria-label={closeButtonLabel}
        >
          <CloseIcon aria-hidden />
        </DialogCloseButton>

        {title && (
          <Spacing mBottom={spacing.mini}>
            <HeadlineText size="large" color={colors.purple.dark1}>
              {title}
            </HeadlineText>
          </Spacing>
        )}
        <BodyText size="medium" textAlign="center" color={colors.grey.light1}>
          {text}
        </BodyText>

        {(primaryButton || secondaryButton) && (
          <DialogActions mTop={spacing.xLarge}>
            {secondaryButton && (
              <Button onClick={secondaryButton.onClick} variant="tertiary">
                {secondaryButton.label}
              </Button>
            )}

            {primaryButton && (
              <Button onClick={primaryButton.onClick}>
                {primaryButton.label}
              </Button>
            )}
          </DialogActions>
        )}
      </DialogContent>

      <DialogListActions mTop={spacing.large}>
        {buttonList?.map(({ onClick, label }, index) => (
          <Fragment key={`dialog-button-list-${index}`}>
            <Button variant="tertiary" onClick={onClick} isFullWidth>
              {label}
            </Button>
            {index + 1 < buttonList.length && <Divider />}
          </Fragment>
        ))}
      </DialogListActions>
    </DialogContainer>
  )
}
