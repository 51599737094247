import FavouriteOutlineThin from '@kijiji/icons/src/icons/FavouriteOutlineThin'
import MessagesIcon from '@kijiji/icons/src/icons/Messages'
import NotificationIcon from '@kijiji/icons/src/icons/Notification'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import React from 'react'

import { RouteLink } from '@/components/shared/route-link'
import { ROUTES } from '@/constants/routes'
import { isUserAuthenticated } from '@/domain/user'
import { useGetUnreadNotificationDataQuery } from '@/generated'
import { trackEvent } from '@/lib/ga'
import { GA_CTA_HEADER } from '@/lib/ga/constants/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'

import { HiddenMobile } from '../styled'
import { IconLink, NotificationsWrapper, UnreadCountBadge } from './styled'

export const LoggedInLinks = () => {
  const { status } = useSession()
  const { t } = useTranslation('global_header')

  const { data } = useGetUnreadNotificationDataQuery({ skip: !isUserAuthenticated(status) })

  const notificationsCount = data?.unreadNotificationsCount || 0
  const messagesCount = data?.unreadConversationsCount || 0

  const trackMessages = () => {
    trackEvent({ action: 'MessageCenterOpen', label: `${GA_CTA_HEADER};unread=${messagesCount};` })
  }

  const trackNotifications = () => {
    trackEvent({
      action: 'NotificationCentreOpen',
      label: `${GA_CTA_HEADER};unread=${notificationsCount};`,
    })
  }

  const trackFavourites = () => {
    trackEvent({ action: GA_EVENT.WatchlistOpen, label: GA_CTA_HEADER })
  }

  return (
    <>
      <HiddenMobile>
        <RouteLink route={ROUTES.MY_FAVOURITES}>
          <IconLink
            aria-label={`${t('links.my_favourites.label')}`}
            onClick={trackFavourites}
            rel="nofollow"
            data-testid="header-link-favourites"
          >
            <FavouriteOutlineThin aria-hidden />
          </IconLink>
        </RouteLink>
      </HiddenMobile>

      <NotificationsWrapper>
        <RouteLink route={ROUTES.MY_NOTIFICATIONS}>
          <IconLink
            aria-label={`${t('links.notifications.label')}, ${notificationsCount}`}
            onClick={trackNotifications}
            data-testid="header-link-notifications"
          >
            <NotificationIcon aria-hidden />
            {notificationsCount ? (
              <UnreadCountBadge data-testid="unread-notifications-count">
                {notificationsCount}
              </UnreadCountBadge>
            ) : null}
          </IconLink>
        </RouteLink>
      </NotificationsWrapper>

      <RouteLink route={ROUTES.MY_MESSAGES}>
        <IconLink
          aria-label={`${t('links.my_messages.label')}, ${messagesCount}`}
          onClick={trackMessages}
          data-testid="header-link-messages"
          rel="nofollow"
        >
          <MessagesIcon aria-hidden data-testid="messages-icon" />
          {messagesCount ? (
            <UnreadCountBadge data-testid="unread-conversations-count">
              {messagesCount}
            </UnreadCountBadge>
          ) : null}
        </IconLink>
      </RouteLink>
    </>
  )
}
