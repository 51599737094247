import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'

import { RouteLink } from '@/components/shared/route-link'
import { ROUTES } from '@/constants/routes'
import { isUserAuthenticated } from '@/domain/user'
import { useSignin } from '@/hooks/useSignin'
import { trackEvent } from '@/lib/ga'
import { GA_CTA_HEADER } from '@/lib/ga/constants/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'

import { PostAdButton } from './styled'

export const PostAdCTA = () => {
  const [goToSignIn, setGoToSignIn] = useState(false)

  const { status } = useSession()
  const { t } = useTranslation('global_header')

  const trackPostAdBegin = () => {
    trackEvent({ action: GA_EVENT.PostAdBegin, label: GA_CTA_HEADER })
  }

  const handleSignInClick = () => {
    /**TODO: it should redirect to postAd link after login is completed */
    setGoToSignIn(true)
  }

  useSignin({ callbackUrl: '/p-select-category.html', goToSignIn })

  /**
   * If user is not authenticated and path is supposed to come from webapp
   * Then POST_AD should be a button to hosted CIS
   */
  if (!isUserAuthenticated(status)) {
    return (
      <PostAdButton data-testid="header-link-post-ad" onClick={handleSignInClick} size="small">
        {t('links.post_ad.label')}
      </PostAdButton>
    )
  }

  return (
    <RouteLink route={ROUTES.POST_AD} prefetch={false}>
      <PostAdButton
        data-testid="header-link-post-ad"
        forwardedAs="a"
        onClick={trackPostAdBegin}
        rel="nofollow"
        size="small"
      >
        {t('links.post_ad.label')}
      </PostAdButton>
    </RouteLink>
  )
}
