import { HTMLAttributes } from 'react'
import { useTheme } from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'
import { StarMeter } from '@/ui/atoms/star-meter'
import { TextLink } from '@/ui/atoms/text-link'

import { RatingContainer } from './styled'

export type RatingProps = HTMLAttributes<HTMLDivElement> & {
  score: number
  size?: 'small' | 'large'
  starMeterLabel: string
  reviewsLabel: string
}

/**
 * @description Rating component that displays user score, rating, and total number of reviews
 *
 * @param score - score out of 5, used to calculate progress and displayed to the user
 * @param size - either small or large to determine the layout
 * @param reviewsLabel - ie. 6 reviews
 * @param starMeterLabel - used for aria-label in star meter ie. 4.5 rating score out of 5
 */

export const Rating = ({
  score,
  size = 'small',
  reviewsLabel,
}: RatingProps) => {
  const theme = useTheme()
  const isSmall = size === 'small'

  return (
    <RatingContainer $size={size}>
      <BodyText
        color={theme.colors.grey.primary}
        id="score"
        weight="bold"
        size={isSmall ? 'small' : 'xLarge'}
      >
        {score}
      </BodyText>
      <StarMeter
        score={score}
        size={size}
        aria-describedby="score totalReviews"
      />
      {isSmall ? (
        <TextLink id="totalReviews" variant="secondary" size="small">
          ({reviewsLabel})
        </TextLink>
      ) : (
        <BodyText
          color={theme.colors.grey.light2}
          id="totalReviews"
          size="medium"
        >
          {reviewsLabel}
        </BodyText>
      )}
    </RatingContainer>
  )
}
