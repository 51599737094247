import { isPositiveInteger } from '@/utils/kijiji-common/number'

/**
 * Checks if the value is a valid number for a category ID.
 * It won't match it against all categories from our codebase, however it will prevent us from
 * checking if the number is !== 0 every time we use it.
 *
 * @param id
 */
export const isValidCategory = (id?: number | null): id is number => {
  return isPositiveInteger(id)
}
