import { GetSearchLoadingStateDocument, useGetSearchLoadingStateQuery } from '@/generated'

export const useSearchLoadingState = () => {
  /** Read current value from the cache */
  const { data, client } = useGetSearchLoadingStateQuery()
  const { srp: { loadingFilters = false, loadingResults = false } = {} } = data || {}

  const setLoadingStates = (
    loadingStates:
      | {
          filters?: boolean
          results?: boolean
        }
      | boolean
  ) => {
    if (typeof loadingStates === 'boolean') {
      client.cache.writeQuery({
        query: GetSearchLoadingStateDocument,
        data: {
          srp: {
            loadingResults: loadingStates,
            loadingFilters: loadingStates,
          },
        },
      })
    } else {
      client.cache.writeQuery({
        query: GetSearchLoadingStateDocument,
        data: {
          srp: {
            loadingResults: loadingStates.results ?? loadingResults,
            loadingFilters: loadingStates.filters ?? loadingFilters,
          },
        },
      })
    }
  }

  return { setLoadingStates, loadingFilters, loadingResults }
}
