import { Loader } from '@googlemaps/js-api-loader'

import { CookieRegistry } from '@/constants/cookieRegistry'
import { type LanguageKey } from '@/domain/locale'
import { GetGoogleMapsKeyDocument } from '@/generated'
import { initializeApollo } from '@/lib/apollo/apolloClient'
import { getCookieByKey } from '@/utils/cookies/getCookieByKey'

export const GoogleMapsError = {
  GOOGLE_MAPS_KEY_ERROR: 'NO_GMK',
  API_LOAD_TIMEOUT_ERROR: 'REQUEST_TIMEOUT',
}

export default class GoogleMaps {
  /** Shared instance */
  private static instance: GoogleMaps

  /** Private non-shared variables */
  private languageKey: LanguageKey
  private apolloClient: ReturnType<typeof initializeApollo>
  private apiKey = ''
  private loader: Loader | null = null

  /** Public variables */
  areAllLibrariesLoaded = false

  /**
   * Returns same instance if language key is unchanged
   * Creates new instance with provided language key
   * @constructor
   */
  private constructor(languageKey: LanguageKey) {
    GoogleMaps.instance = this
    this.languageKey = languageKey
    this.apolloClient = initializeApollo()
    this.areAllLibrariesLoaded = false
    return this
  }

  /**
   * Gets the shared instance, or creates a new one if needed
   * @param languageKey Language key that will be used to initialize the instance
   * @returns existing instance if language key is the same, or a new one
   */
  static getInstance = (languageKey: LanguageKey): GoogleMaps => {
    if (!GoogleMaps.instance) return new GoogleMaps(languageKey)

    return GoogleMaps.instance.languageKey === languageKey
      ? GoogleMaps.instance
      : new GoogleMaps(languageKey)
  }

  /**
   * Returns the GoogleMaps API key either stored as cookies, or sneds out a request
   * @returns GoogleMaps API Key
   */
  private getGoogleMapsAPIKey = async () => {
    /** Function to decode the API key */
    const decodeApiCookie = (cookieString: string) => Buffer.from(cookieString, 'base64').toString()

    /** If there is a key stored in the cookies, return it */
    const gmkCookie = getCookieByKey(document.cookie, CookieRegistry.GOOGLE_MAPS_API_KEY)
    if (gmkCookie.length > 0) {
      this.apiKey = decodeApiCookie(gmkCookie)
      return
    }

    /** Otherwise, fetch it from the apollo client */
    await this.apolloClient.mutate({
      mutation: GetGoogleMapsKeyDocument,
    })
    this.apiKey = decodeApiCookie(
      getCookieByKey(document.cookie, CookieRegistry.GOOGLE_MAPS_API_KEY)
    )
  }

  /** Loads all libraries */
  private loadLibraries = async (): Promise<void> => {
    /** Create new loader */
    this.loader = new Loader({
      apiKey: this.apiKey,
      region: 'CA',
      language: this.languageKey,
      url: 'https://maps.googleapis.com/maps/api/js',
    })

    /*
     * The importLibrary seems to be importing all libraries despite
     * suggesting which ones we want.
     * TODO: Replace the following with imports we need after this
     * issue is fixed: https://github.com/googlemaps/js-api-loader/issues/809
     */
    await this.loader.importLibrary('core')

    /** Mark all libraries as successfully loaded */
    this.areAllLibrariesLoaded = true
  }

  /** Set up the libraries */
  setup = async () => {
    if (this.areAllLibrariesLoaded) return

    /** Get the Maps API Key */
    await this.getGoogleMapsAPIKey()

    /** Load the necessary libraries */
    await this.loadLibraries()
  }
}
