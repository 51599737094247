import { spacing } from '@kijiji/theme'
import { useSelect } from 'downshift'
import { HTMLAttributes } from 'react'
import { useTheme } from 'styled-components'

import { Flex } from '@/ui/atoms/flex'
import { FloatingLabel } from '@/ui/atoms/floating-label'
import { RotatingChevron } from '@/ui/atoms/rotating-chevron'
import { createSequentialId } from '@/ui/helpers/createSequentialId'
import { CommonInputFieldProps } from '@/ui/typings/commonTextInput'

import { FormControl } from '../form-control'
import {
  DropdownList,
  DropdownListItem,
  LabelContainer,
  SelectButton,
} from './styled'

export type DropdownItem = {
  icon?: React.ReactNode
  label: string
  value: string | number
  disabled?: boolean
  badge?: React.ReactNode
}

export type DropdownProps = {
  /**
   * List of items that should appear in the dropdown list
   */
  options: DropdownItem[]
  /**
   * Maximum height for all dropdown options.
   */
  maxHeight?: string
  /**
   * Defines if dropdown is used in a group.
   * It will change styling to allow grouping with other fields.
   */
  isGrouped?: boolean
  /**
   * Function triggered when an item is selected
   */
  onSelectChange: (value?: DropdownItem) => void
  /**
   * Initial value of the dropdown
   * The initial value should be passed if this is a controlled component
   */
  selectedValue?: DropdownItem['value']
} & Omit<CommonInputFieldProps, 'maxLength' | 'value'> &
  HTMLAttributes<HTMLButtonElement>

/**
 * Dropdown component
 */
export const Dropdown = ({
  bottom = '2rem',
  error,
  helperText,
  id,
  isGrouped,
  label,
  maxHeight = '20rem',
  onSelectChange,
  options = [],
  required,
  selectedValue,
  ...rest
}: DropdownProps) => {
  const { colors } = useTheme()

  /** Find option based on its value */
  const selectedItem = options.find(({ value }) => value === selectedValue)

  /** Setup downshift */
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,

    closeMenu,
  } = useSelect({
    id,
    items: options,
    selectedItem,
    itemToString: (item) => item?.label || '',
  })

  const sequentialErrorId = error && createSequentialId('dropdown-error-')()

  return (
    <FormControl
      bottom={bottom}
      error={error}
      helperText={helperText}
      id={id}
      isFullWidth
    >
      <LabelContainer hasSelectedIcon={!!selectedItem?.icon}>
        <div>
          <FloatingLabel
            hasError={error}
            htmlFor={id}
            isFocused={selectedItem}
            {...getLabelProps()}
          >
            {label}
          </FloatingLabel>

          <SelectButton
            isGrouped={isGrouped}
            hasError={!!error}
            hasSelectedIcon={!!selectedItem?.icon}
            {...rest}
            {...getToggleButtonProps({
              'aria-describedby': sequentialErrorId,
              id: id,
              type: 'button',
            })}
          >
            <span>
              {selectedItem?.icon}
              {selectedItem?.label}
              {selectedItem?.badge}
            </span>

            <RotatingChevron isOpen={isOpen} />
          </SelectButton>
        </div>
      </LabelContainer>

      <DropdownList
        aria-required={required}
        isOpen={isOpen}
        maxHeight={maxHeight}
        {...getMenuProps()}
      >
        {isOpen &&
          options.map((item, index) => (
            <DropdownListItem
              isHighlighted={highlightedIndex === index}
              key={`dropdown-list-item-${id}-${item.value}`}
              {...getItemProps({ item, index })}
              onClick={() => {
                if (item.disabled) return
                onSelectChange(item)
                closeMenu()
              }}
              isDisabled={item.disabled}
              aria-disabled={item.disabled}
            >
              <Flex
                alignItems="center"
                gap={spacing.default}
                color={colors.grey.primary}
              >
                {item.icon}
                {item.label}
                {item.badge}
              </Flex>
            </DropdownListItem>
          ))}
      </DropdownList>
    </FormControl>
  )
}
