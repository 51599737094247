import CloseIcon from '@kijiji/icons/src/icons/Close'
import { FC, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import {
  CloseButton,
  Direction,
  DrawerContainer,
} from '@/ui/atoms/drawer/styled'

export type DrawerProps = {
  children: React.ReactNode
  closeButtonLabel: string
  direction?: Direction
  isOpen: boolean
  label: string
  maxHeight?: string
  maxWidth?: string
  onCancel: () => void
  portalRefId?: string
}

/**
 * A drawer component that pops out from the bottom, top, left and right of the page
 *
 * @param closeButtonLabel - The close button accessibility label
 * @param direction - The direction you would like the drawer to open from (i.e. left, right, top). Bottom is the default direction.
 * @param isOpen - Determines the visibility of the component
 * @param label - The drawer accessibility label
 * @param maxHeight - Optional drawer maximum height (i.e. '40rem')
 * @param maxWidth - Optional drawer maximum width (i.e. '40rem')
 * @param onCancel - Accepts a callback that's executed when the user clicks the close button
 * @param portalRefId - The drawer portal ID. Make sure to prepend your ID name with a #.
 */
export const Drawer: FC<DrawerProps> = ({
  children,
  closeButtonLabel,
  direction = 'bottom',
  isOpen,
  label,
  maxHeight = 'initial',
  maxWidth = 'initial',
  onCancel,
  portalRefId = '#drawerPortal',
  ...rest
}) => {
  const ref = useRef<Element | null>(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>(portalRefId)

    // If a portal element is not found - create one and append it to the document body
    if (!ref.current) {
      const rootContainer = document.createElement('div')
      rootContainer.setAttribute('id', portalRefId)
      document.body.appendChild(rootContainer)

      ref.current = rootContainer
    }
    setMounted(true)
  }, [])

  // If the portal is open, and it contains a focusable element, focus on that element. Otherwise the entire page must be traversed before the user reaches the drawer if they're using keyboard navigation.
  useEffect(() => {
    if (isOpen && ref.current) {
      const focusableElement = ref.current.querySelector(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      ) as HTMLElement | null

      if (focusableElement) {
        focusableElement.focus()
      }
    }
  }, [isOpen])

  return mounted && ref.current
    ? createPortal(
        <DrawerContainer
          role="region"
          aria-label={label}
          direction={direction}
          isOpen={isOpen}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          {...rest}
        >
          {children}
          <CloseButton
            aria-label={closeButtonLabel}
            onClick={onCancel}
            data-testid="drawer-close-button"
          >
            <CloseIcon aria-hidden />
          </CloseButton>
        </DrawerContainer>,
        ref.current
      )
    : null
}
