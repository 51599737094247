import { HTMLAttributes } from 'react'

import { Progress, Star, StarMeterContainer, StarsContainer } from './styled'

export type StarMeterProps = {
  /**
   * Sets the HTML id attribute and is used for generating the map key for each Star
   */
  id?: string
  /**
   * Used to calculate how many stars are filled
   */
  score: number
  /**
   * Set the size of the star meter
   * @default "small"
   */
  size?: 'small' | 'large'
} & HTMLAttributes<HTMLDivElement>

export const STAR_LENGTH = 5
export const STARMETER_DEFAULT_ID = 'star'
export const calculateProgress = (score: number, starLength: number) =>
  score ? (score / starLength) * 100 : 0

export const StarMeter = ({
  id = STARMETER_DEFAULT_ID,
  score,
  size = 'small',
}: StarMeterProps) => {
  const progress = calculateProgress(score, STAR_LENGTH)
  const ariaLabel = `${score} out of ${STAR_LENGTH}`

  const Stars = ({ dataTestid }: { dataTestid: string }) => (
    <StarsContainer>
      {[...Array(STAR_LENGTH)].map((_, index) => (
        <Star
          data-testid={dataTestid}
          $length={STAR_LENGTH}
          $size={size}
          key={`${id}-${index}`}
          aria-hidden
        />
      ))}
    </StarsContainer>
  )

  return (
    <StarMeterContainer aria-label={ariaLabel} role="img" id={id}>
      {/* base row of grey stars that creates the container width */}
      <Stars dataTestid="grey-star" />
      {/* top row of yellow stars that sits on top of the grey base - its width determing how much of the meter is filled */}
      <Progress $width={progress} data-testid="progress">
        <Stars dataTestid="yellow-star" />
      </Progress>
    </StarMeterContainer>
  )
}
