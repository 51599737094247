import { IconProps } from '@kijiji/icons/src/components/Icon'
import { BreakpointKeys } from '@kijiji/theme'
import {
  cloneElement,
  FC,
  isValidElement,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { useTheme } from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'
import { Card } from '@/ui/atoms/card'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { IconChip } from '@/ui/atoms/icon-chip'
import { getWindowWidthBreakpointKey } from '@/ui/helpers/getWindowWidthBreakpointKey'
import { StyledAs } from '@/ui/typings/helpers'

import OverviewSectionItem from './OverviewSectionItem'
import {
  AttributeName,
  AttributeValue,
  FeatureChipListItem,
  FeatureSectionRow,
  OverviewBody,
  OverviewFeatures,
  OverviewFeaturesViewMoreButton,
  OverviewFooterAttributeContainer,
  OverviewFooterAttributes,
  OverviewFooterDivider,
  OverviewSection,
} from './styled'

export type OverviewProps = {
  title: string
  titleAs?: StyledAs
  sections: OverviewSection[]
  footerAttributes: {
    key: string
    value: string
    structuredMarkupKey?: string
  }[]
  features: OverviewFeature[]
  viewAllFeaturesLabel?: string
}

export type OverviewSection = {
  title: string
  subTitle?: string
  icon: React.ReactNode
  attributes?: {
    key: string
    value: string
    structuredMarkupKey?: string
  }[]
  placement: 'top' | 'bottom'
}

type OverviewFeature = {
  title: string
  icon?: React.ReactNode
}

const MAX_FEATURES_COUNT: Record<BreakpointKeys, number> = {
  small: 6,
  mediumSmall: 6,
  medium: 8,
  large: 12,
  xLarge: 12,
}

export const OverviewSectionList = ({
  sections,
  isFeaturesEmpty,
}: {
  sections: OverviewSection[]
  isFeaturesEmpty: boolean
}) => {
  const theme = useTheme()

  const topSections = useMemo(
    () => sections.filter((section) => section.placement === 'top'),
    [sections]
  )
  const bottomSections = useMemo(
    () => sections.filter((section) => section.placement === 'bottom'),
    [sections]
  )
  const lastSectionTitle = sections[sections.length - 1].title

  return (
    <Flex flexDirection="column" gap={theme.spacing.large}>
      <FeatureSectionRow gap={theme.spacing.large}>
        {topSections.map((section, index) => {
          const isLastSection = section.title === lastSectionTitle
          return (
            <OverviewSectionItem
              key={index}
              section={section}
              isLastSectionWithNoFeatures={isLastSection && isFeaturesEmpty}
              isSingleItem={topSections.length === 1}
            />
          )
        })}
      </FeatureSectionRow>
      <FeatureSectionRow gap={theme.spacing.large}>
        {bottomSections.map((section, index) => {
          const isLastSection = lastSectionTitle === section.title
          return (
            <OverviewSectionItem
              key={index}
              section={section}
              isLastSectionWithNoFeatures={isLastSection && isFeaturesEmpty}
              isSingleItem={bottomSections.length === 1}
            />
          )
        })}
      </FeatureSectionRow>
    </Flex>
  )
}

export const Overview: FC<OverviewProps> = ({
  title,
  titleAs = 'h3',
  sections,
  features,
  footerAttributes,
  viewAllFeaturesLabel,
}) => {
  const theme = useTheme()
  const [shouldHideExtraFeatures, setShouldHideExtraFeatures] = useState(true)
  const [maxFeaturesCount, setMaxFeaturesCount] = useState(
    MAX_FEATURES_COUNT.small
  )
  const isFeaturesEmpty = features.length === 0
  const extraFeaturesCount = features.length - maxFeaturesCount
  const shouldShowViewMoreButton =
    shouldHideExtraFeatures && extraFeaturesCount > 0
  const extraFeaturesLabel = `+${extraFeaturesCount}`

  useLayoutEffect(() => {
    const handleResize = () => {
      const currentBreakpointKey = getWindowWidthBreakpointKey()
      setMaxFeaturesCount(MAX_FEATURES_COUNT[currentBreakpointKey])
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const expandOverviewFeaturesList = useCallback(() => {
    setShouldHideExtraFeatures(false)
  }, [])

  return (
    <Card>
      <HeadlineText
        size="large"
        weight="medium"
        as={titleAs}
        color={theme.colors.grey.primary}
      >
        {title}
      </HeadlineText>
      <OverviewBody flexDirection="column">
        {sections?.length > 0 && (
          <OverviewSectionList
            sections={sections}
            isFeaturesEmpty={isFeaturesEmpty}
          />
        )}
        {!isFeaturesEmpty && (
          <OverviewFeatures>
            {features.map((feature, index) => {
              const icon =
                isValidElement(feature.icon) &&
                cloneElement(feature.icon, {
                  size: 'default',
                  color: theme.colors.grey.light1,
                } as IconProps)

              if (shouldHideExtraFeatures && index >= maxFeaturesCount) {
                return null
              }

              return (
                <FeatureChipListItem
                  data-testid="features-list-item"
                  key={index}
                >
                  <IconChip
                    icon={icon}
                    text={
                      <BodyText color={theme.colors.grey.primary} size="small">
                        {feature.title}
                      </BodyText>
                    }
                  />
                </FeatureChipListItem>
              )
            })}
            {shouldShowViewMoreButton && (
              <OverviewFeaturesViewMoreButton
                as="button"
                onClick={expandOverviewFeaturesList}
                aria-label={viewAllFeaturesLabel}
                data-testid="view-all-features-button"
              >
                <BodyText color={theme.colors.grey.primary} size="small">
                  {extraFeaturesLabel}
                </BodyText>
              </OverviewFeaturesViewMoreButton>
            )}
          </OverviewFeatures>
        )}
        {footerAttributes?.length > 0 && (
          <>
            <OverviewFooterDivider />
            <OverviewFooterAttributes>
              {footerAttributes.map((footerAttribute) => (
                <OverviewFooterAttributeContainer key={footerAttribute.key}>
                  <AttributeName>{`${footerAttribute.key}: `}</AttributeName>
                  {footerAttribute.structuredMarkupKey ? (
                    <AttributeValue
                      itemProp={footerAttribute.structuredMarkupKey}
                    >{`${footerAttribute.value}`}</AttributeValue>
                  ) : (
                    <AttributeValue>{footerAttribute.value}</AttributeValue>
                  )}
                </OverviewFooterAttributeContainer>
              ))}
            </OverviewFooterAttributes>
          </>
        )}
      </OverviewBody>
    </Card>
  )
}
