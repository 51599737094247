import styled from 'styled-components'

type AvatarContainerProps = {
  hasImage?: boolean
}

export const AvatarContainer = styled.div<AvatarContainerProps>(
  ({ theme, hasImage }) => {
    return `
    align-items: center;
    background-color: ${theme.colors.white};
    border-radius: ${theme.borderRadius.rounded};
    border: ${hasImage ? 0 : 0.2}rem dashed ${theme.colors.purple.light3};
    display: flex;
    height: 9.4rem;
    justify-content: center;
    position: relative;
    width: 9.4rem;

    & svg {
      color: ${theme.colors.purple.light4};
    }
    `
  }
)

export const AvatarImage = styled.img(
  ({ theme }) => `
  height: 100%;
  width: 100%;
  border-radius: ${theme.borderRadius.rounded};
`
)

export const UploadImageButton = styled.button(
  ({ theme }) => `
    align-items: center;
    background-color: ${theme.colors.purple.primary};
    border-radius: ${theme.borderRadius.rounded};
    border: .2rem solid ${theme.colors.white};
    bottom: 0;
    cursor: pointer;
    display: flex;
    height: ${theme.spacing.husky};
    justify-content: center;
    position: absolute;
    right: 0;
    transform: translate(25%, 25%);
    width: ${theme.spacing.husky};
  `
)

export const UploadImageInput = styled.input`
  display: none;
`
