import styled from 'styled-components'

import { Spacing } from '@/ui/atoms/spacing'

export const DialogContainer = styled.div(
  ({ theme }) => `
    background-color: ${theme.colors.white};
    border-radius: ${theme.borderRadius.small};
    max-width: 52rem;
    width: fit-content;
  `
)

export const DialogContent = styled(Spacing)`
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const DialogCloseButton = styled.button(
  ({ theme }) => `
  align-self: flex-end;
  margin-bottom: ${theme.spacing.mini};
  
  & svg {
    color: ${theme.colors.grey.primary};
    height: ${theme.spacing.large};
    width: ${theme.spacing.large};
  }
`
)

export const DialogActions = styled(Spacing)(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.large};
`
)

export const DialogListActions = styled(Spacing)(
  ({ theme }) => `
  display: grid;
  gap: ${theme.spacing.defaultSmall};
  width: 100%;
`
)
