import styled from 'styled-components'

import {
  COMMON_INPUT_STYLE,
  INPUT_BORDER_COLOR,
  INPUT_ERROR_COLOR,
  INPUT_HORIZONTAL_PADDING,
} from '@/ui/constants/commonFormStyle'

type SelectButtonProps = {
  hasError?: boolean
  hasSelectedIcon?: boolean
  isGrouped?: boolean
}

const DROPDOWN_SHADOW = '0 0 .8rem rgba(38, 129, 219, 0.5);'

export const SelectButton = styled.div<SelectButtonProps>(
  ({ theme, hasError, isGrouped, hasSelectedIcon }) => `
  ${COMMON_INPUT_STYLE(theme)}
  align-items: center;
  border-color: ${
    // prettier-ignore
    isGrouped ? 'transparent' : hasError ? INPUT_ERROR_COLOR(theme) : INPUT_BORDER_COLOR(theme)
  };
  display: flex;
  height: 4.8rem;
  justify-content: space-between;
  left: 0;
  padding: 0 ${INPUT_HORIZONTAL_PADDING};
  position: absolute;
  top: 0;
  width: 100%;
  cursor: pointer;
  
  & > span:first-of-type {
   ${!hasSelectedIcon ? `margin-top: ${theme.spacing.defaultSmall};` : ''}
   align-items: baseline;

   display: flex;
   overflow: hidden;
   white-space: nowrap;

    & > svg {
      margin-top: ${theme.spacing.mini};;
      margin-right: ${theme.spacing.defaultSmall};
    }

    span {
      margin-left: ${theme.spacing.defaultSmall};
    }
  }

  &[aria-expanded="true"]{
    box-shadow: ${DROPDOWN_SHADOW};
    border: .1rem solid ${theme.colors.blue.primary};
    border-radius: ${theme.borderRadius.small} ${theme.borderRadius.small} 0 0;
  }
`
)

type LabelContainerProps = {
  hasSelectedIcon?: boolean
}

export const LabelContainer = styled.div<LabelContainerProps>(
  ({ theme, hasSelectedIcon }) => `
  align-items: center;
  display: flex;
  height: 4.8rem;
  justify-content: space-between;
  padding: 0 ${INPUT_HORIZONTAL_PADDING};
  width: 100%;

  label {
    ${hasSelectedIcon ? 'left: 4.8rem' : ''} ;
  }

  span svg {
    height: ${theme.spacing.large};
    width: ${theme.spacing.large};
  }
`
)

type DropdownListProps = {
  hasError?: boolean
  isFocused?: boolean
  isOpen?: boolean
  maxHeight?: number
}

export const DropdownList = styled.ul<DropdownListProps>(
  ({ hasError, isOpen, maxHeight, theme }) => `
  ${isOpen ? `box-shadow: ${DROPDOWN_SHADOW};` : ''};
  background: ${theme.colors.white};
  border-radius: 0 0 ${theme.borderRadius.small} ${theme.borderRadius.small};
  border: .1rem solid ${
    isOpen
      ? hasError
        ? INPUT_ERROR_COLOR(theme)
        : isOpen
          ? theme.colors.blue.primary
          : INPUT_BORDER_COLOR(theme)
      : 'transparent'
  };
  border-top: none;
  list-style-type: none;
  max-height: ${maxHeight || 'unset'};
  overflow-y: auto;

`
)

type DropdownListItemProps = {
  isHighlighted?: boolean
  isDisabled?: boolean
}

export const DropdownListItem = styled.li<DropdownListItemProps>(
  ({ theme, isHighlighted, isDisabled }) => `
  background: ${isHighlighted ? theme.colors.grey.light4 : theme.colors.white};
  cursor: pointer;
  padding: ${theme.spacing.default};

  ${
    isDisabled &&
    `
      pointer-events:none; // disables mouse/touch events including CSS hover/active states, click/tap events in Javascript
      opacity:0.6;

      p {
        font-style: italic;
      }
    `
  }

  svg {
    height: ${theme.spacing.large};
    margin-right: ${theme.spacing.default};
    width: ${theme.spacing.large};
  }
`
)
