import { type DialogProps, Dialog } from '@reach/dialog'
import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { zIndexRegistry } from '@/constants/zIndexRegistry'

type ModalProps = DialogProps & {
  id: string
  label: string
  isOpen: boolean
  onCancel: () => void
}

/**
 * It renders within the web-portal in the root of the app
 */
export const Modal: React.FC<ModalProps> = ({
  id,
  label = 'Modal',
  children,
  isOpen,
  onCancel,
  ...props
}) => {
  const ref = useRef<Element | null>(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>('#portal')

    // If it can't find portal element - create one and append to the body
    if (!ref.current) {
      const rootContainer = document.createElement('div')
      rootContainer.setAttribute('id', 'portal')
      document.body.appendChild(rootContainer)

      ref.current = rootContainer
    }

    setMounted(true)
  }, [])

  return mounted && ref.current
    ? createPortal(
        <Dialog
          aria-label={label}
          isOpen={isOpen}
          onDismiss={onCancel}
          style={{
            background: 'none',
            position: 'relative',
            zIndex: zIndexRegistry.MODAL,
          }}
          {...props}
        >
          {children}
        </Dialog>,
        ref.current
      )
    : null
}
