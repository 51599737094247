import { createRef, RefObject, useCallback, useState } from 'react'

import { FloatingLabel } from '@/ui/atoms/floating-label'
import { Spacing } from '@/ui/atoms/spacing'
import { TextInput } from '@/ui/atoms/text-input'
import { createSequentialId } from '@/ui/helpers/createSequentialId'
import { FormControl, FormControlInput } from '@/ui/molecules/form-control'
import {
  RequirementsBar,
  RequirementsBarProps,
} from '@/ui/molecules/requirements-bar'
import { CommonInputFieldProps } from '@/ui/typings/commonTextInput'

import {
  PasswordVisibilityButton,
  PasswordVisibilityButtonProps,
} from './PasswordVisibilityButton'

export type PasswordFieldProps = {
  /**
   * [Accessibility] Label for the progress bar
   */
  progressLabel?: string
} & Pick<
  PasswordVisibilityButtonProps,
  'passwordHiddenText' | 'passwordVisibleText'
> &
  RequirementsBarProps &
  CommonInputFieldProps

/**
 * @description Password field component
 */
export const PasswordField = ({
  bottom = '2rem',
  error,
  helperText,
  id,
  label,
  passwordHiddenText = '',
  passwordVisibleText = '',
  progressLabel = '',
  validation = [],
  ...rest
}: PasswordFieldProps) => {
  const passwordFieldRef: RefObject<HTMLInputElement> = createRef()
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)

  const sequentialErrorId = error
    ? createSequentialId(`${id}-password-field-error-`)()
    : undefined

  const togglePasswordVisibility = useCallback(() => {
    setIsPasswordVisible((prev) => !prev)
  }, [])

  return (
    <Spacing mBottom={bottom}>
      <FormControl
        bottom="0"
        error={error}
        errorId={sequentialErrorId}
        helperText={helperText}
        id={id}
        isFullWidth
      >
        <FloatingLabel
          hasError={!!error}
          htmlFor={id}
          inputRef={passwordFieldRef}
        >
          {label}
        </FloatingLabel>

        <FormControlInput
          endAdornment={
            <PasswordVisibilityButton
              isPasswordVisible={isPasswordVisible}
              onClick={togglePasswordVisibility}
              passwordHiddenText={passwordHiddenText}
              passwordVisibleText={passwordVisibleText}
            />
          }
          inputRef={passwordFieldRef}
          error={error}
        >
          <TextInput
            data-testid={`password-input-${id}`}
            errorId={sequentialErrorId}
            id={id}
            ref={passwordFieldRef}
            type={isPasswordVisible ? 'text' : 'password'}
            {...rest}
          />
        </FormControlInput>
      </FormControl>

      {validation.length ? (
        <RequirementsBar
          isDirty={!!error}
          validation={validation}
          label={progressLabel}
        />
      ) : null}
    </Spacing>
  )
}
