import styled from 'styled-components'

export const ListingTileContainer = styled.div(
  ({ theme }) => `
    border-radius: ${theme.borderRadius.medium};
    border: .1rem solid ${theme.colors.grey.light4};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    position: relative;

    &:focus-within, &:hover {
      filter: drop-shadow(0 .2rem ${theme.spacing.mini} rgba(0, 0, 0, 0.2));
    }
  `
)

export const ListingTileLink = styled.a`
  flex-grow: 1;
`

export const ListingTileLinkGroup = styled.div(
  ({ theme }) => `
  display: flex;
  height: calc(100% - (${theme.spacing.default} * 2));

  a {
    color: ${theme.colors.grey.primary};
    font-size: 18px;
    text-decoration: none;

    &:focus {
      outline: none;
    }

    &:hover, &:focus {
      text-decoration: underline;
    }

    &:after {
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top 0;
    }

    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @supports (-webkit-line-clamp: 3) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
  `
)

export const ListingImageContainer = styled.div`
  padding-bottom: 100%;
  position: relative;
  width: 100%;
`

export const ListingTileBody = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.white};
  border-bottom-left-radius: ${theme.borderRadius.small};
  border-bottom-right-radius: ${theme.borderRadius.small};
  flex-grow: 1;
  padding: ${theme.spacing.default};
`
)

export const Prices = styled.div(
  ({ theme }) => `
  color: ${theme.colors.grey.primary};
  padding-bottom: 10px;
`
)

export const ListingTilePriceDrop = styled.s(
  ({ theme }) => `
  color: ${theme.colors.grey.light1};
  text-decoration: line-through;
`
)
