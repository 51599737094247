// EPS images are older/legacy eBay served images.
// ZOOM images are newer/updgraded EPS images. Provides support for WEBP etc...
// YAMS images are Adevinta hosted images that we are currently migrating to.

// REGEX
const EPS_IMAGE_SIZE_REGEX = /(.+\$_)\d+(\.(jp[e]?g|png|gif))/gi
const EPS_FORMAT_REGEX =
  /^(https:\/\/|http:\/\/|\/\/)?(.+)\/00\/s(?:\/.+)?\/z\/(.+)\/\$(?:_(\d+))?(?:\.(.*))?(\?(?:.*))?$/
const ZOOM_FORMAT_REGEX =
  /^(https:\/\/|http:\/\/|\/\/)?(.+)\/images\/g\/(.+)\/(?:(?:s-l|s-w|s-h)(\d+)?)?(?:\.(.*))?(\?(?:.*))?$/
const YAMS_KIJIJI_FORMAT_REGEX =
  /^(?:https:\/\/|http:\/\/|\/\/)?(.+)\/api\/(v[0-9])\/(.+)\/(.+)(?:\?rule=kijijica-)(\d+)-(.+)?$/
const YAMS_MOVE_FORMAT_REGEX =
  /^(?:https:\/\/|http:\/\/|\/\/)?(.+)\/api\/(v[0-9])\/(.+)\/(.+)(?:\?rule=move-)(\d+)-(.+)?$/
const YAMS_ICAS_FORMAT_REGEX =
  /^(?:https:\/\/|http:\/\/|\/\/)?(.+)\/api\/(v[0-9])\/(.+)\/(.+)(?:\?rule=r-)(\d+)x(\d+)?$/

const isEpsFormat = (url: string) => EPS_FORMAT_REGEX.test(url)
const isZoomFormat = (url: string) => ZOOM_FORMAT_REGEX.test(url)
const isKijijiYamsFormat = (url: string) => YAMS_KIJIJI_FORMAT_REGEX.test(url)
const isMoveYamsFormat = (url: string) => YAMS_MOVE_FORMAT_REGEX.test(url)
const isICASYamsFormat = (url: string) => YAMS_ICAS_FORMAT_REGEX.test(url)

const YAMS_FORMAT_SIZES = [
  64, 80, 96, 100, 140, 200, 225, 275, 300, 400, 500, 640, 960, 1024, 1200, 1600,
] as const
const ICAS_YAMS_FORMAT_SIZES = [64, 147, 358, 726, 1024] as const
const ZOOM_FORMAT_SIZES = [64, 96, 140, 200, 225, 300, 400, 500, 640, 960, 1200, 1600] as const
export const EPS_FORMAT_SIZES = [0, 2, 8, 14, 20, 27, 35, 59, 71] as const

type YAMS_SIZES = (typeof YAMS_FORMAT_SIZES)[number]
type ICAS_YAMS_SIZES = (typeof ICAS_YAMS_FORMAT_SIZES)[number]
type ZOOM_SIZES = (typeof ZOOM_FORMAT_SIZES)[number]
type EPS_SIZES = (typeof EPS_FORMAT_SIZES)[number]
export type ALL_SIZES = ZOOM_SIZES | EPS_SIZES | YAMS_SIZES | ICAS_YAMS_SIZES
export type EXTENSION = 'JPG' | 'PNG' | 'GIF' | 'WEBP'

const isValidYamsSize = (size: ALL_SIZES): size is YAMS_SIZES =>
  YAMS_FORMAT_SIZES.includes(size as YAMS_SIZES)
const isValidICASSize = (size: ALL_SIZES): size is ICAS_YAMS_SIZES =>
  ICAS_YAMS_FORMAT_SIZES.includes(size as ICAS_YAMS_SIZES)
const isValidZoomSize = (size: ALL_SIZES): size is ZOOM_SIZES =>
  ZOOM_FORMAT_SIZES.includes(size as ZOOM_SIZES)
const isValidEpsSize = (size: ALL_SIZES): size is EPS_SIZES =>
  EPS_FORMAT_SIZES.includes(size as EPS_SIZES)

export const EPS_SIZE_CONVERSION_MAPPINGS: { [key: number]: YAMS_SIZES } = {
  0: 100, // SHOWCASE_THUMBNAIL
  2: 200, // TEASER
  8: 300, // TILES_THUMBNAIL
  14: 64, // THUMBNAIL
  20: 300, // EXTRA_LARGE
  27: 640, // CAS_LARGE
  35: 300, // LARGE
  59: 960, // LIGHTBOX
  71: 300, // CAS_THUMBNAIL
}

export const ICAS_SIZE_CONVERSION_MAPPINGS: { [key: number]: YAMS_SIZES } = {
  147: 140,
  358: 400,
  726: 960,
}

const convertSizeToYams = (size: EPS_SIZES | ICAS_YAMS_SIZES): YAMS_SIZES => {
  if (isValidEpsSize(size)) {
    return EPS_SIZE_CONVERSION_MAPPINGS[size]
  }

  return ICAS_SIZE_CONVERSION_MAPPINGS[size]
}

export const getLegacyImageBySize = (url: string, size: EPS_SIZES = 14) =>
  url.replace(EPS_IMAGE_SIZE_REGEX, `$1${size}$2`)

/**
 * Converts EPS format url to Zoom format url
 * @param url string
 * @param size Valid Zoom Sizes
 * @param ext 'JPG' | 'PNG' | 'GIF' | 'WEBP'
 */
export const getImageFromEpsFormat = (url: string, size: ZOOM_SIZES = 200, ext?: EXTENSION) => {
  const match = url.match(EPS_FORMAT_REGEX)
  if (!match) {
    return url
  }

  const [, protocol, baseUrl, metaGuid, , urlExt] = match
  const imageExt = ext || urlExt

  return `${protocol}${baseUrl}/images/g/${metaGuid}/s-l${size}.${imageExt.toLowerCase()}`
}

/**
 * Returns Zoom format url
 * @param url string
 * @param size Valid Zoom Sizes
 * @param ext 'JPG' | 'PNG' | 'GIF' | 'WEBP'
 */
export const getImageFromZoomFormat = (url: string, size: ZOOM_SIZES = 200, ext?: EXTENSION) => {
  const match = url.match(ZOOM_FORMAT_REGEX)
  if (!match) {
    return url
  }

  const [, protocol, baseUrl, metaGuid, urlSize, urlExt] = match
  const imageSize = size || urlSize
  const imageExt = ext || urlExt

  return `${protocol}${baseUrl}/images/g/${metaGuid}/s-l${imageSize}.${imageExt.toLowerCase()}`
}

/**
 * Returns Kijiji YAMS format url
 * @param url string
 * @param size Valid Yams Sizes
 * @param ext 'JPG' | 'PNG' | 'GIF' | 'WEBP'
 * @param rulePrefix 'kijijica' | 'move' | 'r'
 */
export const getImageFromKijijiYamsFormat = (
  url: string,
  size: YAMS_SIZES = 200,
  ext?: EXTENSION
) => {
  const match = url.match(YAMS_KIJIJI_FORMAT_REGEX)

  if (!match) {
    return url
  }

  const [, distributionDNS, version, bucketAlias, objectID, urlSize, urlExt] = match
  const imageSize = size || urlSize
  const imageExt = ext || urlExt

  return `https://${distributionDNS}/api/${version}/${bucketAlias}/${objectID}?rule=kijijica-${imageSize}-${imageExt.toLowerCase()}`
}

/**
 * Returns Move YAMS format url
 * @param url string
 * @param size Valid Yams Sizes
 * @param ext 'JPG' | 'PNG' | 'GIF' | 'WEBP'
 * @param rulePrefix 'kijijica' | 'move' | 'r'
 */
export const getImageFromMoveYamsFormat = (
  url: string,
  size: YAMS_SIZES = 200,
  ext?: EXTENSION
) => {
  const match = url.match(YAMS_MOVE_FORMAT_REGEX)

  if (!match) {
    return url
  }

  const [, distributionDNS, version, bucketAlias, objectID, urlSize, urlExt] = match
  const imageSize = size || urlSize
  const imageExt = ext || urlExt

  return `https://${distributionDNS}/api/${version}/${bucketAlias}/${objectID}?rule=move-${imageSize}-${imageExt.toLowerCase()}`
}

/**
 * Returns iCAS YAMS format url
 * @param url string
 * @param size Valid iCAS Yams Sizes
 */
export const getImageFromICASYamsFormat = (url: string, size: ICAS_YAMS_SIZES = 147) => {
  const match = url.match(YAMS_ICAS_FORMAT_REGEX)

  if (!match) {
    return url
  }

  const [, distributionDNS, version, bucketAlias, objectID, urlSize] = match
  const imageSize = size || urlSize

  return `https://${distributionDNS}/api/${version}/${bucketAlias}/${objectID}?rule=r-${imageSize}x${imageSize}`
}

/**
 * Changes the image size or extension of various YAMS, Zoom and EPS image urls
 * TODO: Clean this up once we have fully migrated to YAMS.
 * @param url string
 * @param size YAMS, ZOOM or EPS image sizes
 * @param ext 'JPG' | 'PNG' | 'GIF' | 'WEBP'
 */
export const getImageBySize = (
  url: string,
  size: ALL_SIZES,
  ext: EXTENSION = 'JPG',
  zoomFormat = false
) => {
  if (!url) {
    return url
  }

  if (isKijijiYamsFormat(url)) {
    const _size = isValidYamsSize(size) ? size : convertSizeToYams(size)
    return getImageFromKijijiYamsFormat(url, _size, ext)
  }

  if (isMoveYamsFormat(url)) {
    const _size = isValidYamsSize(size) ? size : convertSizeToYams(size)
    return getImageFromMoveYamsFormat(url, _size, ext)
  }

  if (isICASYamsFormat(url) && isValidICASSize(size)) {
    return getImageFromICASYamsFormat(url, size)
  }

  if (!zoomFormat && isValidEpsSize(size)) {
    return getLegacyImageBySize(url, size)
  }

  if (isEpsFormat(url) && isValidZoomSize(size)) {
    return getImageFromEpsFormat(url, size, ext)
  }

  if (isZoomFormat(url) && isValidZoomSize(size)) {
    return getImageFromZoomFormat(url, size, ext)
  }

  return url
}
