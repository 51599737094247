import styled from 'styled-components'

import { RoundedChip } from '@/ui/atoms/rounded-chip'

export const IconChipComponent = styled(RoundedChip)(
  ({ theme }) => `
  margin-top: ${theme.spacing.defaultSmall};
  margin-right: ${theme.spacing.defaultSmall};

  & > svg {
    margin-right: ${theme.spacing.mini};
  }
`
)
