import { HTMLAttributes, useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'
import { ProgressBar } from '@/ui/atoms/progress-bar'
import { Spacing } from '@/ui/atoms/spacing'

import {
  RequirementsBarIncomplete,
  RequirementsBarRequirements,
  RequirementsBarSuccessIcon,
} from './styled'

export type RequirementsBarProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Name used for aria-label on progress bar
   */
  label: string
  /**
   * Array with all validation in progress
   */
  validation: {
    /**
     * Label of requirement to be validated
     */
    label: string
    /**
     * Defines if requirement has been completed
     */
    completed: boolean
  }[]
  /**
   * Indicates whether input password field is dirty
   */
  isDirty: boolean
}

/**
 * @description Progress bar with requirements being validated.
 */

export const RequirementsBar = ({
  label,
  validation,
  isDirty = false,
  ...rest
}: RequirementsBarProps) => {
  const { spacing } = useTheme()
  const [progress, setProgress] = useState<number>(0)

  useEffect(() => {
    const countProgress = validation.filter(
      (item) => item.completed === true
    ).length

    if (countProgress !== progress) {
      setProgress(countProgress)
    }
  }, [validation])

  return (
    <Spacing mTop={spacing.defaultSmall} {...rest}>
      <ProgressBar
        total={validation.length}
        progress={progress}
        label={label}
      />
      <RequirementsBarRequirements>
        {validation.map(({ label, completed }) => (
          <BodyText key={`progress-${label}`} size="small">
            <RequirementsBarSuccessIcon aria-hidden completed={completed} />
            <RequirementsBarIncomplete
              isDirty={isDirty}
              completed={completed}
            />
            {label}
          </BodyText>
        ))}
      </RequirementsBarRequirements>
    </Spacing>
  )
}
