import styled from 'styled-components'

import { COMMON_INPUT_STYLE } from '@/ui/constants/commonFormStyle'

/**
 * @description Text area styled.
 */
export const StyledTextArea = styled.textarea(
  ({ theme }) => `
    ${COMMON_INPUT_STYLE(theme)}
    height: 12rem;
    position: relative;
    resize: none;
  `
)
