import { HTMLAttributes } from 'react'

import { StyledAs } from '@/ui/typings/helpers'

import { GridItemContainer } from './styled'

export type GridItemProps = {
  as?: StyledAs
  /**
   * Aligns the items inside of a cell
   */
  alignSelf?: string
  /**
   * Column where the item ends
   */
  columnEnd?: string
  /**
   * Column where the item starts
   */
  columnStart?: string
  /**
   * Area name that can be referenced by a template
   */
  gridArea?: string
  /**
   * Aligns the items inside of a cell
   * */
  justifySelf?: string
  /**
   * Row where the item ends
   */
  rowEnd?: string
  /**
   * Row where the item starts
   */
  rowStart?: string
} & HTMLAttributes<HTMLDivElement>

/**
 * @description Define properties of a child of the Grid component.
 *
 * Determines a grid item’s location within the grid by referring to specific grid lines.
 * @note It Should always be used as a child of the Grid component.
 */
export const GridItem = ({ children, ...rest }: GridItemProps) => (
  <GridItemContainer {...rest}>{children}</GridItemContainer>
)
