import { RADIUS_SLIDER_HALF_POINT_KMS } from '@/constants/location'
import { type GetLocationFromCoordinatesQuery, type UserLocation } from '@/generated'

/**
 * Parses query result to give location object
 * @param locationFromCoordinates Query result for getLocationFromCoordinates
 * @param fallbackCoordinates Coordinates to fallback to if no coords are returned
 * @returns Location object without radius
 */
export const getLocationFromCoordinatesResult = (
  locationFromCoordinates: GetLocationFromCoordinatesQuery['locationFromCoordinates'],
  fallbackCoordinates: { latitude: number; longitude: number }
): UserLocation | null => {
  if (!locationFromCoordinates?.location || !locationFromCoordinates?.place) return null

  const { location, place } = locationFromCoordinates

  const placeLatitude = place.location?.latitude
  const placeLongitude = place.location?.longitude

  const newLocation: UserLocation = {
    isRegion: false,
    id: location?.id,
    name: location?.name
      ? { en_CA: location.name.en_CA, fr_CA: location.name.fr_CA }
      : { en_CA: '', fr_CA: '' },
    area: {
      address: place?.address || '',
      latitude: placeLatitude || fallbackCoordinates.latitude,
      longitude: placeLongitude || fallbackCoordinates.longitude,
      radius: RADIUS_SLIDER_HALF_POINT_KMS,
    },
  }

  return newLocation
}
